import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import { Machine } from '../../../interfaces/Machine.types';
import {
  selectEditMachineInfoSelectsIsSitesLoading,
  selectEditMachineInfoSelectsSites,
  selectIsEditMachineInfoModalLoading,
  selectIsEditMachineInfoModalVisible,
} from '../../state/machineModalsSelectors';
import { MachineModalsActions } from '../../state/machineModalsSlice';
import { PopConfirm } from '../../../../../../lib/components/PopConfirm/PopConfirm';
import { StyledEditMachineInfoModal } from './EditMachineInfoModal.styles';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Input } from 'lib/components/Input/Input';
import { Select } from 'lib/components/Select/Select';
import { FinanceType, ServicePackage } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineDetailsPanelActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelActions';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { OpenSearch } from 'config/constants';

interface EditMachineInfoForm {
  name: string;
  deviceType: string;
  siteId: string;
  materialNo: string;
  serialNo: string;
  inventoryNo: string;
  serviceType: string;
  financeType: string;
  financeContractNo: string;
  softwareVersion: string;
  activateDate: string;
}
interface EditMachineInfoModalProps {
  machine?: Machine;
}

export const EditMachineInfoModal = ({ machine }: EditMachineInfoModalProps): JSX.Element | null => {
  const [isOpenPop, setIsPopOpen] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [formInstance] = Form.useForm();
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  const isVisible = useSelector(selectIsEditMachineInfoModalVisible);
  const isLoading = useSelector(selectIsEditMachineInfoModalLoading);
  const isSitesLoading = useSelector(selectEditMachineInfoSelectsIsSitesLoading);
  const sites = useSelector(selectEditMachineInfoSelectsSites);

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const handleCancel = useCallback(() => {
    const isFieldsTouched = formInstance.isFieldsTouched();

    if (isFieldsTouched) {
      setIsPopOpen(true);
    } else {
      dispatch(MachineModalsActions.hideEditMachineInfoModal());
      formInstance.resetFields();
    }
  }, [dispatch, formInstance]);

  const onFinish = useCallback(
    (values: EditMachineInfoForm) => {
      if (!machine) return;

      analyticsLinkActivated({
        linkName: AnalyticsLink.MACHINE_DETAILS_EDIT_INFO,
      });

      const params = {
        id: machine.id,
        name: values.name,
        finance: {
          contractNumber: values.financeContractNo,
          financeType: (values.financeType as FinanceType) || undefined,
        },
        inventoryNumber: values.inventoryNo,
        servicePackage: (values.serviceType as ServicePackage) || undefined,
        ...(values.siteId !== machine?.site?.id && {
          siteId: values.siteId === '' ? null : values.siteId,
          siteName: sites.find(site => site.id === values.siteId)?.name,
        }),
      };
      dispatch(MachineDetailsPanelActions.updateMachineInfoRequest(params));
    },
    [analyticsLinkActivated, dispatch, machine, sites]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.EDIT_INFO_MACHINE,
    fields: formInstance.getFieldsValue(),
    isVisible,
  });

  useEffect(() => {
    if (!isVisible) return;
    dispatch(
      MachineModalsActions.getSitesListForSelectRequest({
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
      })
    );
  }, [dispatch, isVisible]);

  const onPopConfirm = (): void => {
    setIsPopOpen(false);
    dispatch(MachineModalsActions.hideEditMachineInfoModal());
    formInstance.resetFields();
  };

  const onPopCancel = (): void => setIsPopOpen(false);

  if (!machine) {
    return null;
  }

  return (
    <StyledEditMachineInfoModal
      className="machine-info-edit-modal"
      title={t('machineModals.editMachineInfo.title')}
      centered
      open={isVisible}
      width={784}
      destroyOnClose={true}
      closable={false}
      footer={[
        <PopConfirm
          key="cancel-button"
          title={t('common.discarding.discardingChanges').toUpperCase()}
          description={t('common.discarding.description')}
          cancelText={t('common.noThanks')}
          okText={t('common.discarding.yesDiscard')}
          open={isOpenPop}
          onConfirm={onPopConfirm}
          onCancel={onPopCancel}
        >
          <TextButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
            {t('common.discardChanges')}
          </TextButton>
        </PopConfirm>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('common.saveAdjustments')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={formInstance}
        name="machine-info-edit"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          name: machine?.name || '',
          deviceType: machine?.type?.name || '',
          siteId: machine?.site?.id,
          materialNo: machine?.materialNumber || '',
          serialNo: machine?.serialNumber || '',
          inventoryNo: machine.metadata?.inventoryNumber || '',
          serviceType: machine.metadata?.service?.servicePackage || '',
          financeType: machine.metadata?.finance?.financeType || '',
          financeContractNo: machine.metadata?.finance?.contractNumber || '',
          softwareVersion: machine?.softwareVersion || '',
          activateDate: DateTime.formatDateByLocale(i18n.language, machine.activationDate, DATE_FORMAT_PATTERN.DATE),
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t('machineModals.editMachineInfo.form.label.description')}
              rules={[{ required: true, message: t('machineModals.editMachineInfo.form.errors.nameRequired') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="serviceType" label={t('machineModals.editMachineInfo.form.label.serviceType')}>
              <Select
                placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
                showSearch={false}
                options={[
                  ...(Object.entries(ServicePackage).map(([_key, value]) => ({
                    label: t(`machineModals.editMachineInfo.servicePackage.${value}`),
                    value,
                  })) || []),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="deviceType" label={t('machineModals.editMachineInfo.form.label.deviceType')}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="financeType" label={t('machineModals.editMachineInfo.form.label.financeType')}>
              <Select
                placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
                showSearch={false}
                options={[
                  ...(Object.entries(FinanceType).map(([_key, value]) => ({
                    label: t(`machineModals.editMachineInfo.financeType.${value}`),
                    value,
                  })) || []),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="siteId" label={t('machineModals.editMachineInfo.form.label.site')}>
              <Select
                loading={isSitesLoading}
                dropdownVisibleState={false}
                options={sites.map(site => ({ value: site.id, label: site.name }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="financeContractNo" label={t('machineModals.editMachineInfo.form.label.financeContractNo')}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="materialNo" label={t('machineModals.editMachineInfo.form.label.materialNo')}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="softwareVersion" label={t('machineModals.editMachineInfo.form.label.softwareVersion')}>
              <Input placeholder={t('machineModals.editMachineInfo.form.placeholder.softwareVersion')} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="serialNo" label={t('machineModals.editMachineInfo.form.label.serialNo')}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="activateDate" label={t('machineModals.editMachineInfo.form.label.activateDate')}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="inventoryNo" label={t('machineModals.editMachineInfo.form.label.inventoryNo')}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledEditMachineInfoModal>
  );
};
