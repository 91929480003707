import styled from 'styled-components';

export const StyledMachineListTabGraphs = styled.div`
  min-height: 436px;
  color: ${(props): string => props.theme.colors.black};

  .machine-report-chart__warning-message {
    background-color: ${(props): string => props.theme.colors.semanticInfo};
    border: 1px solid ${(props): string => props.theme.colors.semanticInfo};
    color: ${(props): string => props.theme.colors.white};
    margin-bottom: ${(props): string => props.theme.spacing.base};

    .ant-alert-icon {
      color: ${(props): string => props.theme.colors.white};
    }

    .ant-alert-close-icon span {
      color: ${(props): string => props.theme.colors.white};
    }
  }

  .machine-report-chart__legend {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .machine-report-chart__title {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.captionSmall};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    text-transform: uppercase;
  }

  .machine-report-chart__body {
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
    padding: 25px;
  }

  .machine-report-chart__hours-unit {
    font-size: 10px;
    margin: 0 0 0 33px;
    color: ${(props): string => props.theme.colors.grey};
  }

  .machine-report-chart__filter {
    display: flex;
    justify-content: space-between;
  }

  .machine-report-chart__chart-note {
    display: flex;
  }

  .machine-report-chart__chart-note-text {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.captionSmall};
    font-weight: ${(props): number => props.theme.fontWeights.captionNews};
  }

  .machine-report-chart__chart-note-square-left,
  .machine-report-chart__chart-note-square-right {
    height: 8px;
    width: 8px;
    margin: 0 10px 0 0;
    border-radius: 1px;
  }

  .machine-report-chart__chart-note-left {
    margin: 0 30px 0 0;
    display: flex;
    align-items: baseline;
  }

  .machine-report-chart__chart-note-right {
    display: flex;
    align-items: baseline;
    margin-right: 15px;
  }

  .machine-report-chart__chart-note-square-left {
    background-color: ${(props): string => props.theme.colors.celadonGreen};
  }

  .machine-report-chart__chart-note-square-right {
    background-color: ${(props): string => props.theme.colors.lightCeladonGreen};
  }

  .machine-report-chart__chart {
    display: flex;
    flex-direction: row;
  }

  .machine-report-chart__bar-stacked-chart {
    margin: 0 0 0 0;
    width: 100%;
    overflow: hidden;
  }

  .machine-report-chart__bar-stacked-chart-wrapper {
    height: 330px;

    .axes {
      > g:nth-child(1) {
        .domainAndTicks {
          .domain {
            stroke: rgba(0, 0, 0, 0.05);
          }

          .tick line {
            visibility: hidden;
          }
        }
      }

      > g:nth-child(2) {
        .domainAndTicks {
          .domain,
          .tick line {
            visibility: hidden;
          }
        }
      }
    }
  }

  .machine-report-chart__chart-arrow {
    width: 20px;
    .machine-report-chart__chart-arrow-icon {
      font-size: 17px;
    }
  }

  .machine-report-chart__chart-arrow-btn {
    padding-bottom: 0px;
  }
`;
