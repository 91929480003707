import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { Optional } from '../../../../../lib/types/Optional';
import { MachineAttachmentCheckNameResponse } from '../../interfaces/MachineAttachment.types';
import { MachineModalsActions } from './machineModalsSlice';
import {
  ICheckNameAddMachineAttachmentModalRequestAction,
  IGetSitesListForSelectErrorAction,
  IGetSitesListForSelectRequestAction,
  IGetSitesListForSelectSuccessAction,
} from './machineModalsActions.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { SiteList } from 'app/modules/site-management/interfaces/Site.types';

export function* checkNameMachineAttachmentSaga(
  action: ICheckNameAddMachineAttachmentModalRequestAction
): Generator<
  GetContextEffect | CallEffect<Optional<MachineAttachmentCheckNameResponse> | void> | PutEffect,
  void,
  IDependencies
> {
  const { machineId, attachmentName } = action.payload;
  const { machineAttachmentService } = yield* getContext<IDependencies>('dependencies');
  try {
    const response: Optional<MachineAttachmentCheckNameResponse> = yield* call(machineAttachmentService.checkName, {
      machineId,
      attachmentName,
    });

    yield* put(MachineModalsActions.checkNameAddMachineAttachmentModalSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineModalsActions.checkNameAddMachineAttachmentModalError({
        error,
      })
    );
  }
}

export function* getSitesListForSelectSaga(
  action: IGetSitesListForSelectRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteList>
  | PutEffect<IGetSitesListForSelectSuccessAction>
  | PutEffect<IGetSitesListForSelectErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listForSelect, action.payload);

    yield* put(MachineModalsActions.getSitesListForSelectSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineModalsActions.getSitesListForSelectError({
        error,
      })
    );
  }
}

export function* machineModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineModalsActions.checkNameAddMachineAttachmentModalRequest, checkNameMachineAttachmentSaga);
  yield* takeLatest(MachineModalsActions.getSitesListForSelectRequest, getSitesListForSelectSaga);
}
