import React, { useCallback, useState } from 'react';
import { Alert, UploadFile } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyPrimaryButton } from '../../../../../../lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from '../../../../../../lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { Machine } from '../../../interfaces/Machine.types';
import {
  selectAddMachineAttachmentModalIsFileNameExisting,
  selectIsAddMachineAttachmentModalLoading,
  selectIsAddMachineAttachmentModalVisible,
} from '../../state/machineModalsSelectors';
import { MachineModalsActions } from '../../state/machineModalsSlice';
import { StyledAddMachineAttachmentModal } from './AddMachineAttachmentModal.styles';
import { AnalyticsForm } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Upload } from 'lib/components/Upload/Upload';
import {
  MACHINE_ATTACHMENT_MAX_FILE_SIZE,
  MACHINE_ATTACHMENT_SUPPORTED_TYPE_CATEGORIES,
} from 'app/modules/machine-inventory/utils/constants';
import { MachineDetailsActions } from 'app/modules/machine-inventory/machine-details/state/machineDetailsActions';
import { Modal } from 'lib/components/Modal/Modal';

export interface AddMachineAttachmentModalProps {
  machine: Machine;
  pageSize: number;
}

export const AddMachineAttachmentModal = ({
  machine,
  pageSize,
}: AddMachineAttachmentModalProps): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsAddMachineAttachmentModalVisible);
  const isLoading = useSelector(selectIsAddMachineAttachmentModalLoading);
  const isFileNameExisting = useSelector(selectAddMachineAttachmentModalIsFileNameExisting);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);

  useAnalyticsSetForm({
    name: AnalyticsForm.ADD_MACHINE_ATTACHMENTS,
    fields: {
      files: files.map(file => ({ name: file.name, size: file.size, type: file.type })),
    },
    isVisible,
  });

  const handleCancel = useCallback(() => {
    dispatch(MachineModalsActions.hideAddMachineAttachmentModal());
    setFiles([]);
  }, [dispatch]);

  const handleOk = useCallback(() => {
    if (!files.length) return;
    if (isFileNameExisting) {
      setIsConfirmModalVisible(true);
      return;
    }
    dispatch(
      MachineDetailsActions.addMachineAttachmentRequest({
        machineId: machine.id,
        file: files[0],
        pageSize,
      })
    );
  }, [files, isFileNameExisting, dispatch, machine.id, pageSize]);

  const handleFilesChange = useCallback(
    (uploadingFiles: UploadFile[]) => {
      if (!uploadingFiles.length) {
        dispatch(MachineModalsActions.addMachineAttachmentModalIsNotFileNameExisting());
        return;
      }
      dispatch(
        MachineModalsActions.checkNameAddMachineAttachmentModalRequest({
          machineId: machine.id,
          attachmentName: uploadingFiles[0].name,
        })
      );
    },
    [dispatch, machine.id]
  );

  const handleOkConfirmModal = useCallback(() => {
    if (!files.length) return;
    setIsConfirmModalVisible(false);
    dispatch(
      MachineDetailsActions.addMachineAttachmentRequest({
        machineId: machine.id,
        file: files[0],
        pageSize,
      })
    );
  }, [dispatch, files, machine.id, pageSize]);

  const handleCancelConfirmModal = useCallback(() => {
    setIsConfirmModalVisible(false);
  }, []);

  return (
    <StyledAddMachineAttachmentModal
      className="edit-machine-attachment-modal"
      title={t('machineModals.addMachineAttachment.title')}
      open={isVisible}
      footer={[
        <LegacySecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('common.save')}
        </LegacyPrimaryButton>,
      ]}
      afterClose={(): void => setFiles([])}
    >
      {isFileNameExisting && (
        <Alert
          className="edit-machine-attachment-modal__name-warning"
          description={t('machineModals.addMachineAttachment.fileNameExistingWarning')}
          type="warning"
          showIcon
        />
      )}
      <Upload
        mode="single"
        fileList={files}
        setFileList={setFiles}
        rules={{
          maxFileSize: MACHINE_ATTACHMENT_MAX_FILE_SIZE,
          fileTypeCats: MACHINE_ATTACHMENT_SUPPORTED_TYPE_CATEGORIES,
        }}
        onFilesChange={handleFilesChange}
      />
      <Modal
        title={t('common.confirmation')}
        open={isConfirmModalVisible}
        footer={[
          <LegacySecondaryButton
            size="s"
            className="cancel-button"
            key="cancel-button"
            onClick={handleCancelConfirmModal}
          >
            {t('common.cancel')}
          </LegacySecondaryButton>,
          <LegacyPrimaryButton key="submit" className="submit-button" type="primary" onClick={handleOkConfirmModal}>
            {t('common.ok')}
          </LegacyPrimaryButton>,
        ]}
      >
        {t('machineModals.addMachineAttachment.fileNameExistingConfirmation')}
      </Modal>
    </StyledAddMachineAttachmentModal>
  );
};
