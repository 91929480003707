import { differenceInHours } from 'date-fns';
import { isString } from 'lodash-es';
import { Optional } from '../../../../lib/types/Optional';
import { IMachineStatusItemData, MachineStatusItemData } from '../interfaces/Machine.types';
import { DataStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const getMachineStatus = (lastActivityAt: string | null): Optional<IMachineStatusItemData> => {
  let hoursSinceLastActivity = null;

  if (isString(lastActivityAt)) {
    // Use the machine's lastActivityAt value
    hoursSinceLastActivity = differenceInHours(new Date(), new Date(lastActivityAt || ''));
  }

  if (hoursSinceLastActivity === null) {
    return MachineStatusItemData[DataStatus.NoData];
  }

  if (hoursSinceLastActivity >= 72) {
    return MachineStatusItemData[DataStatus.GreaterThanThreeDaysOld];
  }

  if (hoursSinceLastActivity >= 24 && hoursSinceLastActivity < 72) {
    return MachineStatusItemData[DataStatus.OneToThreeDaysOld];
  }

  if (hoursSinceLastActivity < 24) {
    return MachineStatusItemData[DataStatus.LessThanOneDayOld];
  }

  return null;
};
