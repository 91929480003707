import { CallEffect, ForkEffect, GetContextEffect, JoinEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, fork, join, select, retry, SagaGenerator } from 'typed-redux-saga';
import { isEqual } from 'lodash-es';
import {
  MachinesExportRequest,
  MachineList,
  MachineListAvailableFilters,
  MachineListVariantData,
  MachinesExportGetFile,
  MachineListAsReport,
  MachinesReportExportRequest,
  MachineReportSubscriptionsListResponse,
  MachineReportSubscriptionsResetResponse,
  MachineFilterResponse,
} from '../../interfaces/Machine.types';
import { MachineClientListAsReportOptions } from '../../MachineClient';
import {
  IRequestExportMachinesErrorAction,
  IRequestExportMachinesRequestAction,
  IRequestExportMachinesSuccessAction,
  IGetMachineListCoordinatesErrorAction,
  IGetMachineListCoordinatesPicturesSuccessAction,
  IGetMachineListCoordinatesRequestAction,
  IGetMachineListCoordinatesSuccessAction,
  IGetMachineListFiltersErrorAction,
  IGetMachineListFiltersSuccessAction,
  IPollGetExportMachinesFileErrorAction,
  IPollGetExportMachinesFileRequestAction,
  IPollGetExportMachinesFileSuccessAction,
  MachineListActions,
  IGetMachineListAsReportRequestAction,
  IGetMachineListAsReportSuccessAction,
  IGetMachineListAsReportErrorAction,
  IPollGetMachineListAsReportErrorAction,
  IRequestExportMachinesReportSuccessAction,
  IRequestExportMachinesReportErrorAction,
  IRequestExportMachinesReportRequestAction,
  IListMachineReportSubscriptionsSuccessAction,
  IListMachineReportSubscriptionsErrorAction,
  IResetMachineReportSubscriptionsRequestAction,
  IResetMachineReportSubscriptionsErrorAction,
  IResetMachineReportSubscriptionsSuccessAction,
  IGetMachineFilterRequestAction,
  IGetMachineFilterSuccessAction,
  IGetMachineFilterErrorAction,
} from './machineListActions';
import * as machineListSelectors from './machineListSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { POLL_MAX_RETRIES, POLL_INTERVAL } from 'config/constants';
import { AsyncJobStatus, MachinesReport } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getMachineListAsReportSaga(
  action: IGetMachineListAsReportRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineListAsReport>
  | ForkEffect<MachineListVariantData>
  | JoinEffect
  | PutEffect<IGetMachineListAsReportSuccessAction>
  | PutEffect<IGetMachineListAsReportErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machinesResponseTask = yield* fork(machineService.listAsReport, action.payload);

    const machinesResponse = yield* join(machinesResponseTask);
    yield* put(MachineListActions.getMachineListAsReportSuccess(machinesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.getMachineListAsReportError({
        error,
      })
    );
  }
}

export function* pollGetMachineListAsReportSaga(): Generator<
  | GetContextEffect
  | SelectEffect
  | SagaGenerator<MachineListAsReport, CallEffect<MachineListAsReport>>
  | PutEffect<IGetMachineListAsReportSuccessAction>
  | PutEffect<IPollGetMachineListAsReportErrorAction>,
  void,
  any
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');
  const getMachineListParams: Optional<MachineClientListAsReportOptions> = yield* select(
    machineListSelectors.selectRequestParams
  );
  const oldMachines: Optional<MachinesReport[]> = yield* select(machineListSelectors.selectData);
  const getMachineList = async (): Promise<MachineListAsReport> => {
    if (!getMachineListParams) {
      throw new Error('Params are not defined!');
    }
    const newMachines = await machineService.listAsReport(getMachineListParams);
    const isEqualObjects = isEqual(oldMachines, newMachines?.machinesReport?.data);

    if (isEqualObjects) {
      throw new Error('No new data!');
    }
    return newMachines;
  };
  try {
    const response: MachineListAsReport = yield retry(POLL_MAX_RETRIES, POLL_INTERVAL + 1000, getMachineList);
    yield* put(MachineListActions.getMachineListAsReportSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.pollGetMachineListAsReportError({
        error,
      })
    );
  }
}

export function* getMachineListFiltersSaga(): Generator<
  | GetContextEffect
  | CallEffect<MachineListAvailableFilters>
  | PutEffect<IGetMachineListFiltersSuccessAction>
  | PutEffect<IGetMachineListFiltersErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.availableFilters);

    yield* put(MachineListActions.getMachineListFiltersSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.getMachineListFiltersError({
        error,
      })
    );
  }
}

export function* getMachineListCoordinatesSaga(
  action: IGetMachineListCoordinatesRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineList>
  | ForkEffect<MachineListVariantData>
  | JoinEffect
  | PutEffect<IGetMachineListCoordinatesSuccessAction>
  | PutEffect<IGetMachineListCoordinatesPicturesSuccessAction>
  | PutEffect<IGetMachineListCoordinatesErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machinesCoordinatesResponseTask = yield* fork(machineService.listCoordinates, action.payload);
    const machinesCoordinatesPicturesResponseTask = yield* fork(machineService.listCoordinatesPictures, action.payload);

    const machinesCoordinatesResponse = yield* join(machinesCoordinatesResponseTask);
    yield* put(MachineListActions.getMachineListCoordinatesSuccess(machinesCoordinatesResponse));

    const machinesCoordinatesPicturesResponse = yield* join(machinesCoordinatesPicturesResponseTask);
    yield* put(MachineListActions.getMachineListCoordinatesPicturesSuccess(machinesCoordinatesPicturesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.getMachineListCoordinatesError({
        error,
      })
    );
  }
}

export function* requestExportMachinesSaga(
  action: IRequestExportMachinesRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<MachinesExportRequest> | void>
  | PutEffect<IRequestExportMachinesSuccessAction>
  | PutEffect<IRequestExportMachinesErrorAction>
  | PutEffect<IPollGetExportMachinesFileRequestAction>,
  void,
  IDependencies
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.requestExportMachines, action.payload);
    yield* put(MachineListActions.requestExportMachinesSuccess(response));
    yield* call(toastService.success, {
      message: t('machineList.toast.success.message'),
      description: t('machineList.toast.success.description'),
    });

    const requestId = yield* select(machineListSelectors.selectExportRequestId);

    if (!requestId) {
      throw new Error('No requestId');
    }

    yield* put(MachineListActions.pollGetExportMachinesFileRequest({ requestId }));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.requestExportMachinesError({
        error,
      })
    );
  }
}

export function* requestExportMachinesReportSaga(
  action: IRequestExportMachinesReportRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachinesReportExportRequest> | void>
  | PutEffect<IRequestExportMachinesReportSuccessAction>
  | PutEffect<IRequestExportMachinesReportErrorAction>,
  void,
  IDependencies
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.requestExportMachinesReport, action.payload);
    yield* put(MachineListActions.requestExportMachinesReportSuccess(response));
    yield* call(toastService.success, {
      message: t('machineList.toast.machinesReportExport.success.message'),
      description: t('machineList.toast.machinesReportExport.success.description'),
    });
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.requestExportMachinesReportError({
        error,
      })
    );
  }
}

export function* pollGetExportMachinesFileSaga(
  action: IPollGetExportMachinesFileRequestAction
): Generator<
  | GetContextEffect
  | SagaGenerator<MachinesExportGetFile, CallEffect<MachinesExportGetFile>>
  | PutEffect<IPollGetExportMachinesFileSuccessAction>
  | PutEffect<IPollGetExportMachinesFileErrorAction>,
  void,
  any
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');
  const getExportMachinesFile = async (): Promise<MachinesExportGetFile> => {
    const getFileResponse = await machineService.getExportMachinesFile(action.payload);
    const { status, downloadUrl } = getFileResponse?.machinesExportFile.data || {};

    if (status !== AsyncJobStatus.Done) {
      throw new Error('Download link is not ready yet!');
    }

    if (!downloadUrl) {
      throw new Error('Download link is ready but no presignedUrl!');
    }

    return getFileResponse;
  };

  try {
    const response: MachinesExportGetFile = yield retry(POLL_MAX_RETRIES, POLL_INTERVAL, getExportMachinesFile);

    yield* put(MachineListActions.pollGetExportMachinesFileSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.pollGetExportMachinesFileError({
        error,
      })
    );
  }
}

export function* listMachineReportSubscriptionsSaga(): Generator<
  | GetContextEffect
  | CallEffect<MachineReportSubscriptionsListResponse>
  | PutEffect<IListMachineReportSubscriptionsSuccessAction>
  | PutEffect<IListMachineReportSubscriptionsErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.listMachineReportSubscriptions);

    yield* put(MachineListActions.listMachineReportSubscriptionsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.listMachineReportSubscriptionsError({
        error,
      })
    );
  }
}

export function* resetMachineReportSubscriptionsSaga(
  action: IResetMachineReportSubscriptionsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineReportSubscriptionsResetResponse> | void>
  | PutEffect<IResetMachineReportSubscriptionsSuccessAction>
  | PutEffect<IResetMachineReportSubscriptionsErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.resetMachineReportSubscriptions, action.payload);

    yield* put(MachineListActions.resetMachineReportSubscriptionsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.resetMachineReportSubscriptionsError({
        error,
      })
    );
  }
}

export function* getMachineListFilterSaga(
  action: IGetMachineFilterRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineFilterResponse> | void>
  | PutEffect<IGetMachineFilterSuccessAction>
  | PutEffect<IGetMachineFilterErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.getMachineFilter, action.payload);

    yield* put(MachineListActions.getMachineFilterSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      MachineListActions.getMachineFilterError({
        error,
      })
    );
  }
}

export function* machineListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineListActions.GET_MACHINE_LIST_AS_REPORT_REQUEST, getMachineListAsReportSaga);
  yield* takeLatest(MachineListActions.POLL_GET_MACHINE_LIST_AS_REPORT_REQUEST, pollGetMachineListAsReportSaga);
  yield* takeLatest(MachineListActions.GET_MACHINE_LIST_FILTERS_REQUEST, getMachineListFiltersSaga);
  yield* takeLatest(MachineListActions.GET_MACHINE_LIST_COORDINATES_REQUEST, getMachineListCoordinatesSaga);
  yield* takeLatest(MachineListActions.REQUEST_EXPORT_MACHINES_REQUEST, requestExportMachinesSaga);
  yield* takeLatest(MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_REQUEST, requestExportMachinesReportSaga);
  yield* takeLatest(MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_REQUEST, pollGetExportMachinesFileSaga);
  yield* takeLatest(MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST, listMachineReportSubscriptionsSaga);
  yield* takeLatest(MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST, resetMachineReportSubscriptionsSaga);
  yield* takeLatest(MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_REQUEST, getMachineListFilterSaga);
}
