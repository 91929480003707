import { useTranslation } from 'react-i18next';
import { Form, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { MachineListActions } from '../../state/machineListActions';
import * as machineListSelectors from '../../state/machineListSelectors';
import { StyledMachineListReportSubscriptionDrawer } from './MachineListReportSubscriptionDrawer.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Radio } from 'lib/components/Radio/Radio';
import {
  MachinesReportSubscriptionReportFileFormat,
  MachinesReportSubscriptionReportInterval,
  MachinesReportSubscriptionReportType,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';

export const MachineListReportSubscriptionDrawer = (): JSX.Element => {
  const [subscriptionForm] = Form.useForm();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const visible = useSelector(machineListSelectors.selectSubscriptionIsVisible) || false;
  const isLoading = useSelector(machineListSelectors.selectSubscriptionIsLoadingSubscriptions) || false;
  const isSaving = useSelector(machineListSelectors.selectSubscriptionIsSavingSubscription) || false;
  const data = useSelector(machineListSelectors.selectSubscriptionData);
  const machineReportSubscription = data?.find(
    datum => datum.reportType === MachinesReportSubscriptionReportType.Total
  );
  const reportIntervalRadios = [
    {
      value: MachinesReportSubscriptionReportInterval.Monthly,
      label: t('machineList.reportSubscription.form.monthly'),
    },
    { value: MachinesReportSubscriptionReportInterval.Weekly, label: t('machineList.reportSubscription.form.weekly') },
    { value: '', label: t('machineList.reportSubscription.form.noReport') },
  ];

  const onFinish = (): void => {
    const reportInterval: MachinesReportSubscriptionReportInterval | string =
      subscriptionForm.getFieldValue('reportInterval');
    if (reportInterval === '') {
      dispatch(MachineListActions.resetMachineReportSubscriptionsRequest({ input: [] }));
      return;
    }

    if (machineReportSubscription) {
      dispatch(
        MachineListActions.resetMachineReportSubscriptionsRequest({
          input: [
            {
              lang: machineReportSubscription.lang,
              reportType: machineReportSubscription.reportType,
              reportFileFormats: machineReportSubscription.reportFileFormats,
              reportInterval: reportInterval as MachinesReportSubscriptionReportInterval,
            },
          ],
        })
      );
    } else {
      dispatch(
        MachineListActions.resetMachineReportSubscriptionsRequest({
          input: [
            {
              lang: Translations.getSupportedLanguageCode(i18n.language),
              reportType: MachinesReportSubscriptionReportType.Total,
              reportFileFormats: [MachinesReportSubscriptionReportFileFormat.Excel],
              reportInterval: reportInterval as MachinesReportSubscriptionReportInterval,
            },
          ],
        })
      );
    }
  };

  const handleClose = (): void => {
    dispatch(MachineListActions.hideMachineReportSubscriptionsDrawer());
  };

  useEffect(() => {
    dispatch(MachineListActions.listMachineReportSubscriptionsRequest());
  }, [dispatch]);

  useEffect(
    () => () => {
      subscriptionForm.resetFields();
    },
    [subscriptionForm]
  );

  return (
    <StyledMachineListReportSubscriptionDrawer
      className="machine-list__report-subscription-panel-drawer"
      size="extra-small"
      open={visible}
      onClose={handleClose}
      loading={isLoading}
    >
      <div className="report-subscription-drawer__header">
        <h1 className="report-subscription-drawer__title">{t('machineList.reportSubscription.title')}</h1>
      </div>
      <div className="report-subscription-drawer__body">
        <p>{t('machineList.reportSubscription.description1')}</p>
        <p>{t('machineList.reportSubscription.description2')}</p>
        <Form
          form={subscriptionForm}
          name="machine-list__subscription-form"
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
        >
          <Form.Item name="reportInterval" initialValue={machineReportSubscription?.reportInterval || ''}>
            <Radio.Group>
              <Space direction="vertical" size="middle">
                {reportIntervalRadios.map(reportIntervalRadio => (
                  <Radio value={reportIntervalRadio.value} key={reportIntervalRadio.value}>
                    {reportIntervalRadio.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>

      <div className="report-subscription-drawer__footer">
        <SecondaryButton onClick={handleClose} size="m" className="report-subscription-drawer__close-button">
          {t('common.cancel')}
        </SecondaryButton>
        <PrimaryButton
          onClick={onFinish}
          size="m"
          loading={isSaving}
          className="report-subscription-drawer__save-button"
        >
          {t('machineList.reportSubscription.saveSubscription')}
        </PrimaryButton>
      </div>
    </StyledMachineListReportSubscriptionDrawer>
  );
};
