export const getImageDownloaded = ({
  imageAsDataUrl,
  imageTitle,
}: {
  imageAsDataUrl: string;
  imageTitle: string;
}): void => {
  try {
    const link = document.createElement('a');
    link.href = imageAsDataUrl;
    link.download = imageTitle;
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error(error);
  }
};
