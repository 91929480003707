import { produce } from 'immer';
import { AnyAction } from 'redux';
import { isNil } from 'lodash-es';
import { Optional } from '../../../../../lib/types/Optional';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  Machine,
  MachinesExportRequest,
  MachineList,
  MachineListAvailableFilters,
  MachinesExportGetFile,
} from '../../interfaces/Machine.types';
import { MachineUtils } from '../../utils/MachineUtils';
import { RobotListActions } from './robotListActions';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import {
  FilterLastActivityAt,
  MachineConnectionStatus,
  Maybe,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface RobotListState {
  data: Optional<Machine[]>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  arePicturesLoading: boolean;
  paginationTokens: PaginationTokens;
  page: number;
  pageSize: number;
  filters: {
    available: MachineListAvailableFilters;
    active: {
      machineType: Optional<string>;
      materialNumber: Optional<string>;
      site: Optional<string>;
      lastActivityAt: Optional<FilterLastActivityAt>;
      connectionStatuses: Optional<MachineConnectionStatus[]>;
      searchText: Optional<string>;
    };
    isLoading: boolean;
  };
  map: {
    data: Optional<Machine[]>;
    isLoading: Optional<boolean>;
    arePicturesLoading: boolean;
  };
  sortOptions: {
    field: Maybe<string>;
    order: Maybe<SortOrders>;
  };
  export: {
    isLoading: Optional<boolean>;
    requestId: Optional<string>;
    downloadUrl: Optional<string>;
  };
}

export const initialState: RobotListState = {
  data: null,
  totalCount: null,
  isLoading: null,
  arePicturesLoading: true,
  paginationTokens: {},
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  filters: {
    available: {
      machineTypes: [],
      sites: [],
      machineStatus: [],
    },
    active: {
      machineType: undefined,
      materialNumber: undefined,
      site: undefined,
      lastActivityAt: undefined,
      connectionStatuses: undefined,
      searchText: undefined,
    },
    isLoading: false,
  },
  map: {
    data: null,
    isLoading: null,
    arePicturesLoading: true,
  },
  sortOptions: {
    field: null,
    order: null,
  },
  export: {
    isLoading: null,
    requestId: null,
    downloadUrl: null,
  },
};

export const robotListReducer = (state = initialState, action: AnyAction): RobotListState =>
  produce(state, draft => {
    switch (action.type) {
      case RobotListActions.GET_MACHINE_LIST_REQUEST: {
        draft.isLoading = true;
        draft.arePicturesLoading = true;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_SUCCESS: {
        const {
          machines: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.isLoading = false;
        draft.data = data;
        draft.totalCount = totalCount;

        if (paginationToken) {
          draft.paginationTokens[draft.page + 1] = paginationToken;
        }

        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_PICTURES_SUCCESS: {
        const {
          machines: { data: machineVariantData },
        } = action.payload;

        draft.arePicturesLoading = false;

        draft.data = draft.data?.map(machineWithoutVariantData =>
          MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
        );

        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case RobotListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.page = page;
        return draft;
      }

      case RobotListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = pageSize;
        return draft;
      }

      case RobotListActions.RESET_STATE: {
        return initialState;
      }

      case RobotListActions.GET_MACHINE_LIST_FILTERS_REQUEST: {
        draft.filters.isLoading = true;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_FILTERS_SUCCESS: {
        draft.filters.isLoading = false;
        draft.filters.available = action.payload;

        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_FILTERS_ERROR: {
        draft.filters.isLoading = false;
        return draft;
      }

      case RobotListActions.SET_ACTIVE_MATERIAL_NUMBER_FILTER: {
        const { materialNumber } = action.payload;

        draft.filters.active.materialNumber = materialNumber;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case RobotListActions.SET_ACTIVE_SITE_FILTER: {
        const { site } = action.payload;

        draft.filters.active.site = site;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case RobotListActions.SET_ACTIVE_MACHINE_TYPE_FILTER: {
        const { machineType } = action.payload;

        draft.filters.active.machineType = machineType;

        // Reset material number when changing machine type
        draft.filters.active.materialNumber = '';
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case RobotListActions.SET_ACTIVE_MACHINE_STATUS_FILTER: {
        const { lastActivityAt, connectionStatuses } = action.payload;

        draft.filters.active.lastActivityAt = lastActivityAt;
        draft.filters.active.connectionStatuses = connectionStatuses;

        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case RobotListActions.SET_SEARCH_TEXT_FILTER: {
        const { searchText } = action.payload;

        draft.filters.active.searchText = searchText;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case RobotListActions.SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.sortOptions.field = field;
        return draft;
      }

      case RobotListActions.SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.sortOptions.order = order;
        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_COORDINATES_REQUEST: {
        draft.map.isLoading = true;
        draft.map.arePicturesLoading = true;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_COORDINATES_SUCCESS: {
        const {
          machines: { data },
        } = action.payload as MachineList;

        draft.map.data = data.filter(machine => !isNil(machine.location));
        draft.map.isLoading = false;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS: {
        const {
          machines: { data: machineVariantData },
        } = action.payload;

        draft.map.arePicturesLoading = false;
        draft.map.data = draft.map.data?.map(machineWithoutVariantData =>
          MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
        );

        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_COORDINATES_ERROR: {
        draft.map.isLoading = false;
        return draft;
      }

      case RobotListActions.REQUEST_EXPORT_MACHINES_REQUEST: {
        draft.export.isLoading = true;
        return draft;
      }

      case RobotListActions.REQUEST_EXPORT_MACHINES_SUCCESS: {
        const {
          machinesListExportRequest: { data },
        } = action.payload as MachinesExportRequest;

        draft.export.requestId = data.requestId;

        return draft;
      }

      case RobotListActions.REQUEST_EXPORT_MACHINES_ERROR: {
        draft.export.isLoading = false;
        return draft;
      }

      case RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_SUCCESS: {
        const {
          machinesExportFile: { data },
        } = action.payload as MachinesExportGetFile;

        draft.export.downloadUrl = data.downloadUrl;
        draft.export.isLoading = false;

        return draft;
      }

      case RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_ERROR: {
        draft.export.isLoading = false;
        return draft;
      }

      case RobotListActions.EXPORT_RESET_STATE: {
        draft.export = initialState.export;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS: {
        const {
          machines: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.isLoading = false;
        draft.data = data;
        draft.totalCount = totalCount;

        if (paginationToken) {
          draft.paginationTokens[draft.page + 1] = paginationToken;
        }

        return draft;
      }

      case RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
