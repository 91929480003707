import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Machine, MachineAsReport, MachineListAvailableFilters } from '../../interfaces/Machine.types';
import { IGetMachineListAsReportRequestOptions } from './machineListActions';
import {
  InputPeriod,
  MachineConnectionStatus,
  MachineFilter,
  MachinesReportSubscription,
  Maybe,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectData = (state: IState): Optional<MachineAsReport[]> =>
  state.modules['machine-inventory']['machine-list'].data;

export const selectRequestParams = (state: IState): Optional<IGetMachineListAsReportRequestOptions> =>
  state.modules['machine-inventory']['machine-list'].requestParams;

export const selectTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-list'].totalCount;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].isLoading;

export const selectPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-list'].paginationTokens;

export const selectPage = (state: IState): number => state.modules['machine-inventory']['machine-list'].page;

export const selectPageSize = (state: IState): number => state.modules['machine-inventory']['machine-list'].pageSize;

export const selectFilters = (state: IState): MachineListAvailableFilters =>
  state.modules['machine-inventory']['machine-list'].filters.available;

export const selectAreFiltersLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-list'].filters.isLoading;

export const selectActiveMaterialNumberFilter = (state: IState): Optional<string[]> =>
  state.modules['machine-inventory']['machine-list'].filters.active.materialNumbers || undefined;

export const selectActiveSiteFilter = (state: IState): Optional<string[]> =>
  state.modules['machine-inventory']['machine-list'].filters.active.sites || undefined;

export const selectActiveMachineTypeFilter = (state: IState): Optional<string[]> =>
  state.modules['machine-inventory']['machine-list'].filters.active.machineTypes || [];

export const selectActiveMachineStatusFilter = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-list'].filters.active.machineStatus;

export const selectActiveConnectionStatusesFilter = (state: IState): Optional<MachineConnectionStatus[]> =>
  state.modules['machine-inventory']['machine-list'].filters.active.connectionStatuses || undefined;

export const selectActivePeriodFilter = (state: IState): InputPeriod =>
  state.modules['machine-inventory']['machine-list'].filters.active.period;

export const selectActiveSearchTextFilter = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-list'].filters.active.searchText || undefined;

export const selectSortField = (state: IState): Maybe<string> =>
  state.modules['machine-inventory']['machine-list'].sortOptions.field;

export const selectSortOrder = (state: IState): Maybe<SortOrders> =>
  state.modules['machine-inventory']['machine-list'].sortOptions.order;

export const selectMapMachines = (state: IState): Optional<Machine[]> =>
  state.modules['machine-inventory']['machine-list'].map.data;

export const selectIsMapLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].map.isLoading;

export const selectExportRequestId = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-list'].export.requestId;

export const selectExportDownloadUrl = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-list'].export.downloadUrl;

export const selectExportIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].export.isLoading;

export const selectSubscriptionIsVisible = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].subscription.visible;

export const selectSubscriptionIsLoadingSubscriptions = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].subscription.isLoadingSubscriptions;

export const selectSubscriptionIsSavingSubscription = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].subscription.isSavingSubscription;

export const selectSubscriptionData = (state: IState): Optional<MachinesReportSubscription[]> =>
  state.modules['machine-inventory']['machine-list'].subscription.data;

export const selectIsMachineFilterLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-list'].newFilters.isLoading;

export const selectMachineFilterData = (state: IState): Optional<MachineFilter> =>
  state.modules['machine-inventory']['machine-list'].newFilters.data;
