import { CallEffect, ForkEffect, GetContextEffect, JoinEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, fork, join, select, retry, SagaGenerator } from 'typed-redux-saga';
import {
  MachinesExportRequest,
  MachineList,
  MachineListAvailableFilters,
  MachineListVariantData,
  MachinesExportGetFile,
} from '../../interfaces/Machine.types';
import {
  IRequestExportMachinesErrorAction,
  IRequestExportMachinesRequestAction,
  IRequestExportMachinesSuccessAction,
  IGetMachineListCoordinatesErrorAction,
  IGetMachineListCoordinatesPicturesSuccessAction,
  IGetMachineListCoordinatesRequestAction,
  IGetMachineListCoordinatesSuccessAction,
  IGetMachineListErrorAction,
  IGetMachineListFiltersErrorAction,
  IGetMachineListFiltersSuccessAction,
  IGetMachineListPicturesSuccessAction,
  IGetMachineListRequestAction,
  IGetMachineListSuccessAction,
  IPollGetExportMachinesFileErrorAction,
  IPollGetExportMachinesFileRequestAction,
  IPollGetExportMachinesFileSuccessAction,
  RobotListActions,
} from './robotListActions';
import * as machineListSelectors from './robotListSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { POLL_MAX_RETRIES, POLL_INTERVAL } from 'config/constants';
import { AsyncJobStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getMachineListSaga(
  action: IGetMachineListRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineList>
  | ForkEffect<MachineListVariantData>
  | JoinEffect
  | PutEffect<IGetMachineListSuccessAction>
  | PutEffect<IGetMachineListPicturesSuccessAction>
  | PutEffect<IGetMachineListErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machinesResponseTask = yield* fork(machineService.list, action.payload);
    const machinePicturesResponseTask = yield* fork(machineService.listPictures, action.payload);

    const machinesResponse = yield* join(machinesResponseTask);
    yield* put(RobotListActions.getMachineListSuccess(machinesResponse));

    const machinePicturesResponse = yield* join(machinePicturesResponseTask);
    yield* put(RobotListActions.getMachineListPicturesSuccess(machinePicturesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotListActions.getMachineListError({
        error,
      })
    );
  }
}

export function* getMachineListFiltersSaga(): Generator<
  | GetContextEffect
  | CallEffect<MachineListAvailableFilters>
  | PutEffect<IGetMachineListFiltersSuccessAction>
  | PutEffect<IGetMachineListFiltersErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.availableFilters);

    yield* put(RobotListActions.getMachineListFiltersSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotListActions.getMachineListFiltersError({
        error,
      })
    );
  }
}

export function* getMachineListCoordinatesSaga(
  action: IGetMachineListCoordinatesRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineList>
  | ForkEffect<MachineListVariantData>
  | JoinEffect
  | PutEffect<IGetMachineListCoordinatesSuccessAction>
  | PutEffect<IGetMachineListCoordinatesPicturesSuccessAction>
  | PutEffect<IGetMachineListCoordinatesErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machinesCoordinatesResponseTask = yield* fork(machineService.listCoordinates, action.payload);
    const machinesCoordinatesPicturesResponseTask = yield* fork(machineService.listCoordinatesPictures, action.payload);

    const machinesCoordinatesResponse = yield* join(machinesCoordinatesResponseTask);
    yield* put(RobotListActions.getMachineListCoordinatesSuccess(machinesCoordinatesResponse));

    const machinesCoordinatesPicturesResponse = yield* join(machinesCoordinatesPicturesResponseTask);
    yield* put(RobotListActions.getMachineListCoordinatesPicturesSuccess(machinesCoordinatesPicturesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotListActions.getMachineListCoordinatesError({
        error,
      })
    );
  }
}

export function* requestExportMachinesSaga(
  action: IRequestExportMachinesRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<MachinesExportRequest> | void>
  | PutEffect<IRequestExportMachinesSuccessAction>
  | PutEffect<IRequestExportMachinesErrorAction>
  | PutEffect<IPollGetExportMachinesFileRequestAction>,
  void,
  IDependencies
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.requestExportMachines, action.payload);
    yield* put(RobotListActions.requestExportMachinesSuccess(response));
    yield* call(toastService.success, {
      message: t('machineList.toast.success.message'),
      description: t('machineList.toast.success.description'),
    });

    const requestId = yield* select(machineListSelectors.selectExportRequestId);

    if (!requestId) {
      throw new Error('No requestId');
    }

    yield* put(RobotListActions.pollGetExportMachinesFileRequest({ requestId }));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotListActions.requestExportMachinesError({
        error,
      })
    );
  }
}

export function* pollGetExportMachinesFileSaga(
  action: IPollGetExportMachinesFileRequestAction
): Generator<
  | GetContextEffect
  | SagaGenerator<MachinesExportGetFile, CallEffect<MachinesExportGetFile>>
  | PutEffect<IPollGetExportMachinesFileSuccessAction>
  | PutEffect<IPollGetExportMachinesFileErrorAction>,
  void,
  any
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');
  const getExportMachinesFile = async (): Promise<MachinesExportGetFile> => {
    const getFileResponse = await machineService.getExportMachinesFile(action.payload);
    const { status, downloadUrl } = getFileResponse?.machinesExportFile.data || {};

    if (status !== AsyncJobStatus.Done) {
      throw new Error('Download link is not ready yet!');
    }

    if (!downloadUrl) {
      throw new Error('Download link is ready but no presignedUrl!');
    }

    return getFileResponse;
  };

  try {
    const response: MachinesExportGetFile = yield retry(POLL_MAX_RETRIES, POLL_INTERVAL, getExportMachinesFile);

    yield* put(RobotListActions.pollGetExportMachinesFileSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotListActions.pollGetExportMachinesFileError({
        error,
      })
    );
  }
}

export function* getMachineListWithoutImageSaga(
  action: IGetMachineListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachineList>
  | PutEffect<IGetMachineListSuccessAction>
  | PutEffect<IGetMachineListErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.list, action.payload);

    yield* put(RobotListActions.getMachineListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotListActions.getMachineListError({
        error,
      })
    );
  }
}

export function* robotListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(RobotListActions.GET_MACHINE_LIST_REQUEST, getMachineListSaga);
  yield* takeLatest(RobotListActions.GET_MACHINE_LIST_FILTERS_REQUEST, getMachineListFiltersSaga);
  yield* takeLatest(RobotListActions.GET_MACHINE_LIST_COORDINATES_REQUEST, getMachineListCoordinatesSaga);
  yield* takeLatest(RobotListActions.REQUEST_EXPORT_MACHINES_REQUEST, requestExportMachinesSaga);
  yield* takeLatest(RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_REQUEST, pollGetExportMachinesFileSaga);
  yield* takeLatest(RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST, getMachineListWithoutImageSaga);
}
