import { i18n as i18next } from 'i18next';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { TooltipRendererProps } from 'react-charts/types/components/TooltipRenderer';
import { TFunction } from 'react-i18next';
import { DefaultTheme } from 'styled-components';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import {
  ACTUAL_CHART_COLUMN_LABEL,
  PLANNED_CHART_COLUMN_LABEL,
  ReactChartsDatum,
  ReactChartUtils,
} from 'app/utils/react-charts/ReactChartUtils';

interface MachineListTabGraphsTooltipDataProps {
  seriesId: string;
  seriesLabel: string;
  hoursTitle: string;
  percent: string;
  isHighlight: boolean;
  colorSquareIcon: string;
  isTopBorder: boolean;
}

/**
 * @description copy from SitePerformanceChartTooltip.tsx with "machine-report-chart" prefix class name.
 */
export const MachineListTabGraphsTooltip = (
  startDate: string,
  endDate: string,
  t: TFunction,
  i18n: i18next,
  theme: DefaultTheme,
  tooltipRendererProps: TooltipRendererProps<ReactChartsDatum>
): React.ReactNode => {
  let plannedMs = 0;
  let actualMs = 0;
  let deviationMs = 0;
  let deviationPercent = t('common.NA');
  const { focusedDatum } = tooltipRendererProps;
  let tooltipGroup = focusedDatum?.tooltipGroup || [];
  const seriesIndex = focusedDatum?.seriesIndex;
  const title = ReactChartUtils.extractColumnName(focusedDatum?.originalDatum?.primary);
  const formattedStartDate = DateTime.formatDateByLocale(i18n.language, startDate, DATE_FORMAT_PATTERN.DATE);
  const formattedEndDate = DateTime.formatDateByLocale(i18n.language, endDate, DATE_FORMAT_PATTERN.DATE);
  const subtitle = `${formattedStartDate} — ${formattedEndDate}`;
  let deviationNotAvailable = true;

  if (tooltipGroup.length) {
    actualMs = DateTime.roundMsDurationToMinutes({
      ms: tooltipGroup.find(e => e.seriesLabel === ACTUAL_CHART_COLUMN_LABEL)?.secondaryValue || 0,
    });

    plannedMs = DateTime.roundMsDurationToMinutes({
      ms: tooltipGroup.find(e => e.seriesLabel === PLANNED_CHART_COLUMN_LABEL)?.secondaryValue || 0,
    });

    if (plannedMs) {
      deviationNotAvailable = false;
      deviationMs = actualMs - plannedMs;
      deviationPercent = [parseFloat(((deviationMs * 100) / plannedMs).toFixed(0)), '%'].join('');
    }
  }

  // Order tooltip properties, actual always a first item
  tooltipGroup = tooltipGroup.sort((a, b) => a.seriesLabel.localeCompare(b.seriesLabel));

  const tooltipData: MachineListTabGraphsTooltipDataProps[] = tooltipGroup.map(colData => {
    const { seriesLabel, seriesId, secondaryValue } = colData;
    const isHighlight = colData.seriesIndex === seriesIndex;
    const isPlanned = seriesLabel === PLANNED_CHART_COLUMN_LABEL;
    const hoursTitle = [
      DateTime.formatDurationByMilliseconds({
        ms: secondaryValue,
        showSeconds: false,
      }),
      t('operatingHoursComparisonChart.hUnit'),
    ].join('');
    const colorSquareIcon = isPlanned ? theme.colors.lightCeladonGreen : theme.colors.celadonGreen;
    let percent = '0%';
    if (!!secondaryValue && !!plannedMs) {
      percent = [parseFloat(((secondaryValue * 100) / plannedMs).toFixed(0)), '%'].join('');
    }

    return {
      seriesId,
      seriesLabel,
      hoursTitle,
      percent,
      isHighlight,
      colorSquareIcon,
      isTopBorder: false,
    };
  });

  // Add deviation row in tooltip
  tooltipData.push({
    seriesId: 'deviation',
    seriesLabel: 'operatingHoursComparisonChart.deviation',
    hoursTitle: deviationNotAvailable
      ? t('common.NA')
      : [
          DateTime.formatDurationByMilliseconds({ ms: deviationMs || 0, showSeconds: false }),
          t('operatingHoursComparisonChart.hUnit'),
        ].join(''),
    percent: deviationPercent,
    isHighlight: true,
    colorSquareIcon: 'transparent',
    isTopBorder: true,
  });

  return (
    <div
      className="machine-report-chart__tooltip"
      style={{
        fontFamily: theme.fonts.captionNews,
      }}
    >
      <p className="machine-report-chart__tooltip-title">{title}</p>
      <p className="machine-report-chart__tooltip-subtitle">{subtitle}</p>
      <div>
        {tooltipData.map(
          ({ seriesId, seriesLabel, hoursTitle, percent, isHighlight, colorSquareIcon, isTopBorder }, i) => (
            <div
              key={`tooltip-item-${i}-${seriesId}`}
              className={`machine-report-chart__tooltip-item ${isTopBorder ? 'border' : ''}`}
            >
              <div
                className="machine-report-chart__tooltip-item-square-icon"
                style={{ backgroundColor: colorSquareIcon }}
              />
              <div
                className="machine-report-chart__tooltip-item-text-block"
                style={{
                  opacity: isHighlight ? 1 : 0.5,
                }}
              >
                <p className="machine-report-chart__tooltip-item-seriesLabel">{`${t(seriesLabel)}`}</p>

                <div className="machine-report-chart__tooltip-item-hours-percent">
                  <p className="machine-report-chart__tooltip-item-hours">{`${hoursTitle}`}</p>
                  <p className="machine-report-chart__tooltip-item-percent">{percent}</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
