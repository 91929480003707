import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect, TakeEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getContext, call, put, takeLatest, take, throttle, cancel, takeEvery, fork } from 'typed-redux-saga';
import { EventChannel } from 'redux-saga';
import { isNil } from 'lodash-es';
import {
  RobotCleaningTaskCleanedAreaByDayListResponse,
  RobotCleaningTaskCleanedHrsByDayListResponse,
  RobotCleaningTaskConsumablesByDayListResponse,
  RobotCleaningTaskDistanceDrivenByDayListResponse,
  RoutesNameListResponse,
} from '../../interfaces/Robot.types';
import { SubscriptionMachineUpdateResult } from '../../interfaces/Machine.types';
import {
  MachineUpdateConnectionStatus,
  MachineUpdateRobotStatus,
  MachineUpdateTelemetry,
  SubscriptionMachineEvent,
} from '../../interfaces/MachineSubscription.types';
import { MachineDetailsRobotActions, SubscribeToMachineUpdateAction } from './machineDetailsRobotSlice';
import {
  MachineDetailsRobotListCleaningDataCleanedAreaRequestAction,
  MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction,
  MachineDetailsRobotListCleaningDataCleanedHrsRequestAction,
  MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction,
  MachineDetailsRobotListConsumablesRequestAction,
  MachineDetailsRobotListConsumablesSuccessAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction,
  MachineDetailsRobotListRoutesNameRequestAction,
  MachineDetailsRobotListRoutesNameSuccessAction,
} from './machineDetailsRobotActions.types';
import { MachineDetailsActions, MachineDetailsRobotRealTimePropertyChangedAction } from './machineDetailsActions';
import { IActionCreatorErrorOptions } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { SagaUtils } from 'app/cross-cutting-concerns/state-management/utils/SagaUtils';
import { GraphQlSubscriptionMessage } from 'app/cross-cutting-concerns/communication/interfaces/graphql.types';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import {
  MachineConnectionStatus,
  MachineUpdate,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getRoutesNameListSaga(
  action: MachineDetailsRobotListRoutesNameRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RoutesNameListResponse>>
  | PutEffect<MachineDetailsRobotListRoutesNameSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.listRoutesName, action.payload);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotGetRoutesNameListSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotGetRoutesNameListError({ error }));
  }
}

export function* getCleaningTaskCleanedAreaByDayListSaga(
  action: MachineDetailsRobotListCleaningDataCleanedAreaRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskCleanedAreaByDayListResponse>>
  | PutEffect<MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskCleanedAreaByDayList, action.payload);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataCleanedAreaSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataCleanedAreaError({ error }));
  }
}

export function* getCleaningTaskCleanedHrsByDayListSaga(
  action: MachineDetailsRobotListCleaningDataCleanedHrsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskCleanedHrsByDayListResponse>>
  | PutEffect<MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskCleanedHrsByDayList, action.payload);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataCleanedHrsSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataCleanedHrsError({ error }));
  }
}

export function* getCleaningTaskConsumablesByDayListSaga(
  action: MachineDetailsRobotListConsumablesRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskConsumablesByDayListResponse>>
  | PutEffect<MachineDetailsRobotListConsumablesSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskConsumablesByDayList, action.payload);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataConsumablesSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataConsumablesError({ error }));
  }
}

export function* getCleaningTaskDistanceDrivenByDayListSaga(
  action: MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<RobotCleaningTaskDistanceDrivenByDayListResponse>>
  | PutEffect<MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction>
  | PutEffect<PayloadAction<IActionCreatorErrorOptions>>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getCleaningTaskDistanceDrivenByDayList, action.payload);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataDistanceDrivenSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(MachineDetailsRobotActions.machineDetailsRobotListCleaningDataConsumablesError({ error }));
  }
}

export function* onMachineTelemetryChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as Record<string, string>;

  const machineUpdatedStatusData: MachineUpdateTelemetry = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(MachineDetailsActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData }));
}

export function* onMachineStatusChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as {
    robotStatus: RobotStatus;
  };

  const machineUpdatedStatusData: MachineUpdateRobotStatus = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(MachineDetailsActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData }));
}

export function* onMachineConnectionStatusChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as {
    connectionStatus: MachineConnectionStatus;
  };

  const machineUpdatedStatusData: MachineUpdateConnectionStatus = {
    ...machineUpdated,
    data: machineUpdatedData,
  };
  yield* put(MachineDetailsActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData }));
}

export function* onEveryMachineUpdateSaga(
  message: GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>
): Generator<
  GetContextEffect | PutEffect<MachineDetailsRobotRealTimePropertyChangedAction> | SelectEffect | ForkEffect
> {
  const machineUpdated = message.value.data.onMachineUpdate;
  const event = machineUpdated?.event;

  try {
    if (!isNil(event)) {
      switch (event) {
        case SubscriptionMachineEvent.TELEMETRY_CHANGED:
          yield* fork(onMachineTelemetryChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_STATUS_CHANGED:
          yield* fork(onMachineStatusChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_CONNECTION_STATUS_CHANGED:
          yield* fork(onMachineConnectionStatusChanged, machineUpdated);
          break;

        default:
          break;
      }
    }
  } catch (error) {
    console.error('machineId', message.value.data.onMachineUpdate.machineId);
    console.error(error);
  }
}

export function* subscribeToMachineUpdate(
  subscribeAction: SubscribeToMachineUpdateAction
): Generator<
  | GetContextEffect
  | CallEffect<EventChannel<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>>
  | ForkEffect<never>
  | TakeEffect
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { observable, throttleDuration } = machineService.subscribeToMachineUpdate({
      customerId: subscribeAction.payload.customerId,
      machineId: subscribeAction.payload.machineId,
    });

    const channel = yield* call(
      SagaUtils.createChannelFromObservable<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>,
      observable
    );

    yield* throttle(throttleDuration, channel, onEveryMachineUpdateSaga);

    while (true) {
      const unsubscribeAction = yield* take(MachineDetailsRobotActions.unsubscribeFromMachineUpdate);
      if (
        unsubscribeAction.payload.customerId === subscribeAction.payload.customerId &&
        unsubscribeAction.payload.machineId === subscribeAction.payload.machineId
      ) {
        channel.close();
        cancel();
      }
    }
  } catch (err) {
    console.error('Error while subscribing/unsubscribing to subscription:', err);
  }
}

export function* machineDetailsRobotSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineDetailsRobotActions.machineDetailsRobotGetRoutesNameListRequest, getRoutesNameListSaga);
  yield* takeLatest(
    MachineDetailsRobotActions.machineDetailsRobotListCleaningDataCleanedAreaRequest,
    getCleaningTaskCleanedAreaByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsRobotActions.machineDetailsRobotListCleaningDataCleanedHrsRequest,
    getCleaningTaskCleanedHrsByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsRobotActions.machineDetailsRobotListCleaningDataConsumablesRequest,
    getCleaningTaskConsumablesByDayListSaga
  );
  yield* takeLatest(
    MachineDetailsRobotActions.machineDetailsRobotListCleaningDataDistanceDrivenRequest,
    getCleaningTaskDistanceDrivenByDayListSaga
  );
  yield* takeEvery(MachineDetailsRobotActions.subscribeToMachineUpdate, subscribeToMachineUpdate);
}
