import {
  CleaningTaskCleanedAreaByDay,
  CleaningTaskCleanedHrsByDay,
  CleaningTaskConsumablesByDay,
  CleaningTaskDistanceDrivenByDay,
  ConsumablesSummary,
  RobotCleaningRoutesObject,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectAvailableRoutes = (state: IState): Optional<RobotCleaningRoutesObject>[] =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsRobotFilter.availableFilter
    ?.robotCleaningRoutesList.data.validRoute || [];

export const selectUnavailableRoutes = (state: IState): Optional<RobotCleaningRoutesObject>[] =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsRobotFilter.availableFilter
    ?.robotCleaningRoutesList.data.unavailableRoute || [];
export const selectActiveRoutesName = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsRobotFilter.active?.routeName;
export const selectRoutesNameIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsRobotFilter.isLoading;

export const selectPeriodStartDate = (state: IState): string =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsRobotFilter.active.period.startDate;
export const selectPeriodEndDate = (state: IState): string =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsRobotFilter.active.period.endDate;

export const selectCleaningDataCleanedAreaIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedArea.isLoading;
export const selectCleaningDataPeriodCleanedArea = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedArea.data
    ?.cleanedAreaSummary.periodCleanedArea;
export const selectCleaningDataTotalCleanedArea = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedArea.data
    ?.cleanedAreaSummary.totalCleanedArea;
export const selectCleaningDataCleanedAreaByDay = (state: IState): CleaningTaskCleanedAreaByDay[] =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedArea.data
    ?.cleanedAreaList || [];

export const selectCleaningDataCleanedHrsIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedHrs.isLoading;
export const selectCleaningDataPeriodCleanedHrs = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedHrs.data
    ?.cleanedHrsSummary.periodCleanedHrs;

export const selectCleaningDataTotalCleanedHrs = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedHrs.data
    ?.cleanedHrsSummary?.totalCleanedHrs;
export const selectCleaningDataCleanedHrsByDay = (state: IState): CleaningTaskCleanedHrsByDay[] =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataCleanedHrs.data
    ?.cleanedHrsList || [];

export const selectCleaningDataConsumablesIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataConsumables.isLoading;
export const selectCleaningDataConsumablesPeriod = (state: IState): Optional<ConsumablesSummary> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataConsumables.data
    ?.consumablesSummary;
export const selectCleaningDataConsumablesByDay = (state: IState): CleaningTaskConsumablesByDay[] =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataConsumables.data
    ?.consumablesList || [];

export const selectCleaningDataDistanceDrivenIsLoading = (state: IState): boolean =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataDistanceDriven.isLoading;
export const selectCleaningDataPeriodDistanceDriven = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataDistanceDriven.data
    ?.distanceDrivenSummary.periodDistanceDriven;

export const selectCleaningDataTotalDistanceDriven = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataDistanceDriven.data
    ?.distanceDrivenSummary.totalDistanceDriven;
export const selectCleaningDataDistanceDrivenByDay = (state: IState): CleaningTaskDistanceDrivenByDay[] =>
  state.modules['machine-inventory']['machine-details-robot'].machineDetailsCleaningDataDistanceDriven.data
    ?.distanceDrivenList || [];
