import {
  Machine,
  MachineLatestCtrDatum,
  MachineLatestRoutineDatum,
  MachineTelemtryDatum,
  MachineVariantDatum,
} from '../interfaces/Machine.types';

export class MachineUtils {
  public static getFullName = ({ type, name }: Machine): string => [type?.name, name].filter(Boolean).join(' - ');

  public static mergeMachineWithVariantData(
    machineWithoutVariantData: Machine,
    machinesVariantData: MachineVariantDatum[]
  ): Machine {
    const machineVariantDatum = machinesVariantData.find((mvd: any) => mvd.id === machineWithoutVariantData.id);

    if (!machineVariantDatum?.variant) return machineWithoutVariantData;

    return {
      ...machineWithoutVariantData,
      variant: machineVariantDatum.variant,
    };
  }

  public static mergeMachineWithTelemetryData(
    machineWithoutTelemetryData: Machine,
    machineTelemetryData: MachineTelemtryDatum[]
  ): Machine {
    const machineTelemetryDatum = machineTelemetryData.find((mtd: any) => mtd.id === machineWithoutTelemetryData.id);

    if (!machineTelemetryDatum?.states) return machineWithoutTelemetryData;

    return {
      ...machineWithoutTelemetryData,
      states: machineTelemetryDatum.states,
    };
  }

  public static mergeMachineWithLatestCtrData(
    machineWithoutLatestCtrData: Machine,
    machineLatestCtrData: MachineLatestCtrDatum[]
  ): Machine {
    const machineLatestCtrDatum = machineLatestCtrData.find(
      (mlctrd: any) => mlctrd.id === machineWithoutLatestCtrData.id
    );

    if (!machineLatestCtrDatum?.cleaningTaskReportLatestGet) return machineWithoutLatestCtrData;

    return {
      ...machineWithoutLatestCtrData,
      cleaningTaskReportLatestGet: machineLatestCtrDatum.cleaningTaskReportLatestGet,
    };
  }

  public static mergeMachineWithLatestRoutineData(
    machineWithoutLatestRoutineData: Machine,
    machineLatestRoutineData: MachineLatestRoutineDatum[]
  ): Machine {
    const machineLatestRoutineDatum = machineLatestRoutineData.find(
      (mlrd: any) => mlrd.id === machineWithoutLatestRoutineData.id
    );

    if (!machineLatestRoutineDatum?.latestRoutine) return machineWithoutLatestRoutineData;

    return {
      ...machineWithoutLatestRoutineData,
      latestRoutine: machineLatestRoutineDatum.latestRoutine,
    };
  }
}
