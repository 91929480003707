import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Machine } from '../../interfaces/Machine.types';
import { Reminder } from '../../../reminder/interfaces/Reminder.types';
import { IMachineAttachment } from '../../interfaces/MachineAttachment.types';
import {
  CleaningTaskReport,
  MachineNote,
  Maybe,
  Notification,
  OperatingTimePeriod,
  RobotCleaningConsumption,
  RobotTaskCompletionStatisticData,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectData = (state: IState): Optional<Machine> =>
  state.modules['machine-inventory']['machine-details'].data;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].isLoading;

export const selectIsPictureLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].isPictureLoading;

export const selectIsTelemetryLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].isTelemetryLoading;

export const selectIsLatestCtrLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].isLatestCtrLoading;

export const selectMachineNotificationsData = (state: IState): Optional<Notification[]> =>
  state.modules['machine-inventory']['machine-details'].notifications.data;

export const selectMachineNotificationsTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details'].notifications.totalCount;

export const selectIsMachineNotificationsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].notifications.isLoading;

export const selectMachineNotificationsPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details'].notifications.paginationTokens;

export const selectMachineNotificationsPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].notifications.page;

export const selectMachineNotificationsPageSize = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].notifications.pageSize;

export const selectMachineNotificationsSortField = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details'].notifications.sortOptions.field;

export const selectMachineNotificationsSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules['machine-inventory']['machine-details'].notifications.sortOptions.order;

export const selectOperatingTime = (state: IState): Optional<OperatingTimePeriod> =>
  state.modules['machine-inventory']['machine-details'].operatingTime.data;

export const selectOperatingTimeLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].operatingTime.isLoading;

export const selectRemindersData = (state: IState): Optional<Reminder[]> =>
  state.modules['machine-inventory']['machine-details'].reminders.data;

export const selectRemindersTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details'].reminders.totalCount;

export const selectRemindersIsInitialLoad = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].reminders.isInitialLoad;

export const selectRemindersIsLoadingMore = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].reminders.isLoadingMore;

export const selectRemindersPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details'].reminders.paginationTokens;

export const selectRemindersPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].reminders.page;

export const selectNotesData = (state: IState): Optional<MachineNote[]> =>
  state.modules['machine-inventory']['machine-details'].notes.data;

export const selectNotesTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details'].notes.totalCount;

export const selectNotesIsInitialLoad = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].notes.isInitialLoad;

export const selectNotesIsLoadingMore = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].notes.isLoadingMore;

export const selectNotesPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details'].notes.paginationTokens;

export const selectNotesPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].notes.page;

export const selectAttachmentsData = (state: IState): Optional<IMachineAttachment[]> =>
  state.modules['machine-inventory']['machine-details'].attachments.data;

export const selectAttachmentsTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details'].attachments.totalCount;

export const selectAttachmentsIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].attachments.isLoading;

export const selectAttachmentsPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details'].attachments.paginationTokens;

export const selectAttachmentsPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].attachments.page;

export const selectAttachmentsPageSize = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].attachments.pageSize;

export const selectAttachmentsSortField = (state: IState): Maybe<string> =>
  state.modules['machine-inventory']['machine-details'].attachments.sortOptions.field;

export const selectAttachmentsSortOrder = (state: IState): Maybe<SortOrders> =>
  state.modules['machine-inventory']['machine-details'].attachments.sortOptions.order;

export const selectAttachmentsDownloadsIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].attachments.downloads.isLoading;

export const selectTaskCompletionData = (state: IState): Optional<RobotTaskCompletionStatisticData> =>
  state.modules['machine-inventory']['machine-details'].taskCompletionHistory.data;

export const selectTaskCompletionIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].taskCompletionHistory.isLoading;

export const selectCTRListRobotData = (state: IState): Optional<CleaningTaskReport[]> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.data;

export const selectCTRListRobotLatestData = (state: IState): Optional<CleaningTaskReport> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.latestData;

export const selectCTRListPage = (state: IState): number =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.page;

export const selectCTRListRobotPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.paginationTokens;

export const selectCTRListRobotTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.totalCount;

export const selectCTRListIsInitialLoad = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.isInitialLoad;

export const selectCTRListIsLoadingMore = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.isLoadingMore;

export const selectRobotRouteImageUrl = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.routeImage.url;

export const selectRobotRouteImageAsDataUrl = (state: IState): Optional<string> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.routeImage.imageObjectUrl;

export const selectRobotRouteImageIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].cleaningTaskReports.routeImage.isLoading;

export const selectRobotCleaningConsumptionSummary = (state: IState): Optional<RobotCleaningConsumption> =>
  state.modules['machine-inventory']['machine-details'].cleaningConsumptionSummary.data;

export const selectRobotCleaningConsumptionSummaryIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory']['machine-details'].cleaningConsumptionSummary.isLoading;
