import { Action } from 'redux';
import {
  FilterLastActivityAt,
  SortOrders,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  MachinesExportRequest,
  MachineList,
  MachineListAvailableFilters,
  MachineListVariantData,
  MachinesExportGetFile,
} from '../../interfaces/Machine.types';
import {
  MachineClientRequestExportMachinesOptions,
  MachineClientListCoordinatesOptions,
  MachineClientListOptions,
  MachineClientGetExportMachinesFileOptions,
} from '../../MachineClient';
import { Optional } from 'lib/types/Optional';

export type IGetMachineListRequestOptions = MachineClientListOptions;
export type IGetMachineListRequestAction = Action<typeof RobotListActions.GET_MACHINE_LIST_REQUEST> & {
  payload: MachineClientListOptions;
};

export type IGetMachineListSuccessOptions = MachineList;
export type IGetMachineListSuccessAction = Action<typeof RobotListActions.GET_MACHINE_LIST_SUCCESS> & {
  payload: MachineList;
};

export type IGetMachineListPicturesSuccessOptions = MachineListVariantData;
export type IGetMachineListPicturesSuccessAction = Action<typeof RobotListActions.GET_MACHINE_LIST_PICTURES_SUCCESS> & {
  payload: MachineListVariantData;
};

export type IGetMachineListErrorAction = IErrorAction<typeof RobotListActions.GET_MACHINE_LIST_ERROR>;

export type IChangeMachineListTablePage = Action<typeof RobotListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeMachineListTablePageSize = Action<typeof RobotListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type IGetMachineListFiltersRequestAction = Action<typeof RobotListActions.GET_MACHINE_LIST_FILTERS_REQUEST>;

export type IGetMachineListFiltersSuccessOptions = MachineListAvailableFilters;
export type IGetMachineListFiltersSuccessAction = Action<typeof RobotListActions.GET_MACHINE_LIST_FILTERS_REQUEST> & {
  payload: MachineListAvailableFilters;
};

export type IGetMachineListFiltersErrorAction = IErrorAction<typeof RobotListActions.GET_MACHINE_LIST_FILTERS_ERROR>;

export type ISetActiveMaterialNumberFilter = Action<typeof RobotListActions.SET_ACTIVE_MATERIAL_NUMBER_FILTER> & {
  payload: {
    materialNumber: Optional<string>;
  };
};

export type ISetActiveSiteFilter = Action<typeof RobotListActions.SET_ACTIVE_SITE_FILTER> & {
  payload: {
    site: Optional<string>;
  };
};

export type ISetActiveMachineTypeFilter = Action<typeof RobotListActions.SET_ACTIVE_MACHINE_TYPE_FILTER> & {
  payload: {
    machineType: Optional<string>;
  };
};

export type ISetActiveMachineStatusFilter = Action<typeof RobotListActions.SET_ACTIVE_MACHINE_STATUS_FILTER> & {
  payload: {
    lastActivityAt: Optional<FilterLastActivityAt>;
  };
};

export type ISetSearchTextFilter = Action<typeof RobotListActions.SET_SEARCH_TEXT_FILTER> & {
  payload: {
    searchText: Optional<string>;
  };
};

export type ISetActiveSortField = Action<typeof RobotListActions.SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveSortOrder = Action<typeof RobotListActions.SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IGetMachineListCoordinatesRequestOptions = MachineClientListCoordinatesOptions;
export type IGetMachineListCoordinatesRequestAction = Action<
  typeof RobotListActions.GET_MACHINE_LIST_COORDINATES_REQUEST
> & {
  payload: MachineClientListCoordinatesOptions;
};

export type IGetMachineListCoordinatesSuccessOptions = MachineList;
export type IGetMachineListCoordinatesSuccessAction = Action<
  typeof RobotListActions.GET_MACHINE_LIST_COORDINATES_REQUEST
> & {
  payload: MachineList;
};

export type IGetMachineListCoordinatesPicturesSuccessOptions = MachineListVariantData;
export type IGetMachineListCoordinatesPicturesSuccessAction = Action<
  typeof RobotListActions.GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS
> & {
  payload: MachineListVariantData;
};

export type IGetMachineListCoordinatesErrorAction = IErrorAction<
  typeof RobotListActions.GET_MACHINE_LIST_COORDINATES_ERROR
>;

export type IRequestExportMachinesRequestOptions = MachineClientRequestExportMachinesOptions;
export type IRequestExportMachinesRequestAction = Action<typeof RobotListActions.REQUEST_EXPORT_MACHINES_REQUEST> & {
  payload: MachineClientRequestExportMachinesOptions;
};

export type IRequestExportMachinesSuccessOptions = Optional<MachinesExportRequest>;
export type IRequestExportMachinesSuccessAction = Action<typeof RobotListActions.REQUEST_EXPORT_MACHINES_SUCCESS> & {
  payload: Optional<MachinesExportRequest>;
};

export type IRequestExportMachinesErrorAction = IErrorAction<typeof RobotListActions.REQUEST_EXPORT_MACHINES_ERROR>;

export type IPollGetExportMachinesFileRequestOptions = MachineClientGetExportMachinesFileOptions;
export type IPollGetExportMachinesFileRequestAction = Action<
  typeof RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_REQUEST
> & {
  payload: IPollGetExportMachinesFileRequestOptions;
};

export type IPollGetExportMachinesFileSuccessOptions = MachinesExportGetFile;
export type IPollGetExportMachinesFileSuccessAction = Action<
  typeof RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_SUCCESS
> & {
  payload: MachinesExportGetFile;
};

export type IPollGetExportMachinesFileErrorAction = IErrorAction<
  typeof RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_ERROR
>;

export class RobotListActions {
  public static GET_MACHINE_LIST_REQUEST = 'ROBOT_LIST::GET_MACHINE_LIST_REQUEST';
  public static GET_MACHINE_LIST_SUCCESS = 'ROBOT_LIST::GET_MACHINE_LIST_SUCCESS';
  public static GET_MACHINE_LIST_PICTURES_SUCCESS = 'ROBOT_LIST::GET_MACHINE_LIST_PICTURES_SUCCESS';
  public static GET_MACHINE_LIST_ERROR = 'ROBOT_LIST::GET_MACHINE_LIST_ERROR';

  public static CHANGE_TABLE_PAGE = 'ROBOT_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'ROBOT_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static RESET_STATE = 'ROBOT_LIST::RESET_STATE';

  public static GET_MACHINE_LIST_FILTERS_REQUEST = 'ROBOT_LIST::GET_MACHINE_LIST_FILTERS_REQUEST';
  public static GET_MACHINE_LIST_FILTERS_SUCCESS = 'ROBOT_LIST::GET_MACHINE_LIST_FILTERS_SUCCESS';
  public static GET_MACHINE_LIST_FILTERS_ERROR = 'ROBOT_LIST::GET_MACHINE_LIST_FILTERS_ERROR';

  public static SET_ACTIVE_MATERIAL_NUMBER_FILTER = 'ROBOT_LIST::SET_ACTIVE_MATERIAL_NUMBER_FILTER';
  public static SET_ACTIVE_SITE_FILTER = 'ROBOT_LIST::SET_ACTIVE_SITE_FILTER';
  public static SET_ACTIVE_MACHINE_TYPE_FILTER = 'ROBOT_LIST::SET_ACTIVE_MACHINE_TYPE_FILTER';
  public static SET_ACTIVE_MACHINE_STATUS_FILTER = 'ROBOT_LIST::SET_ACTIVE_MACHINE_STATUS_FILTER';

  public static SET_SEARCH_TEXT_FILTER = 'ROBOT_LIST::SET_SEARCH_TEXT_FILTER';

  public static SET_ACTIVE_SORT_FIELD = 'ROBOT_LIST::SET_ACTIVE_SORT_FIELD';
  public static SET_ACTIVE_SORT_ORDER = 'ROBOT_LIST::SET_ACTIVE_SORT_ORDER';

  public static GET_MACHINE_LIST_COORDINATES_REQUEST = 'ROBOT_LIST::GET_MACHINE_LIST_COORDINATES_REQUEST';
  public static GET_MACHINE_LIST_COORDINATES_SUCCESS = 'ROBOT_LIST::GET_MACHINE_LIST_COORDINATES_SUCCESS';
  public static GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS =
    'ROBOT_LIST::GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS';

  public static GET_MACHINE_LIST_COORDINATES_ERROR = 'ROBOT_LIST::GET_MACHINE_LIST_COORDINATES_ERROR';

  public static REQUEST_EXPORT_MACHINES_REQUEST = 'ROBOT_LIST::REQUEST_EXPORT_MACHINES_REQUEST';
  public static REQUEST_EXPORT_MACHINES_SUCCESS = 'ROBOT_LIST::REQUEST_EXPORT_MACHINES_SUCCESS';
  public static REQUEST_EXPORT_MACHINES_ERROR = 'ROBOT_LIST::REQUEST_EXPORT_MACHINES_ERROR';

  public static POLL_GET_EXPORT_MACHINES_FILE_REQUEST = 'ROBOT_LIST::POLL_GET_EXPORT_MACHINES_FILE_REQUEST';
  public static POLL_GET_EXPORT_MACHINES_FILE_SUCCESS = 'ROBOT_LIST::POLL_GET_EXPORT_MACHINES_FILE_SUCCESS';
  public static POLL_GET_EXPORT_MACHINES_FILE_ERROR = 'ROBOT_LIST::POLL_GET_EXPORT_MACHINES_FILE_ERROR';
  public static EXPORT_RESET_STATE = 'ROBOT_LIST::EXPORT_RESET_STATE';

  public static GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST = 'ROBOT_LIST::GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST';
  public static GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS = 'ROBOT_LIST::GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS';
  public static GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR = 'ROBOT_LIST::GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR';

  public static getMachineListRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetMachineListRequestOptions): IGetMachineListRequestAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getMachineListSuccess(data: IGetMachineListSuccessOptions): IGetMachineListSuccessAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListPicturesSuccess(
    data: IGetMachineListPicturesSuccessOptions
  ): IGetMachineListPicturesSuccessAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_PICTURES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListError({ error }: IActionCreatorErrorOptions): IGetMachineListErrorAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeMachineListTablePage {
    return {
      type: RobotListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeMachineListTablePageSize {
    return {
      type: RobotListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof RobotListActions.RESET_STATE> {
    return {
      type: RobotListActions.RESET_STATE,
    };
  }

  public static getMachineListFiltersRequest(): IGetMachineListFiltersRequestAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_FILTERS_REQUEST,
    };
  }

  public static getMachineListFiltersSuccess(
    availableFilters: IGetMachineListFiltersSuccessOptions
  ): IGetMachineListFiltersSuccessAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_FILTERS_SUCCESS,
      payload: availableFilters,
    };
  }

  public static getMachineListFiltersError({ error }: IActionCreatorErrorOptions): IGetMachineListFiltersErrorAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_FILTERS_ERROR,
      error,
    };
  }

  public static setActiveMaterialNumberFilter(materialNumber: Optional<string>): ISetActiveMaterialNumberFilter {
    return {
      type: RobotListActions.SET_ACTIVE_MATERIAL_NUMBER_FILTER,
      payload: {
        materialNumber,
      },
    };
  }

  public static setActiveSiteFilter(site: Optional<string>): ISetActiveSiteFilter {
    return {
      type: RobotListActions.SET_ACTIVE_SITE_FILTER,
      payload: {
        site,
      },
    };
  }

  public static setActiveMachineTypeFilter(machineType: Optional<string>): ISetActiveMachineTypeFilter {
    return {
      type: RobotListActions.SET_ACTIVE_MACHINE_TYPE_FILTER,
      payload: {
        machineType,
      },
    };
  }

  public static setActiveMachineStatusFilter(
    lastActivityAt: Optional<FilterLastActivityAt>
  ): ISetActiveMachineStatusFilter {
    return {
      type: RobotListActions.SET_ACTIVE_MACHINE_STATUS_FILTER,
      payload: {
        lastActivityAt,
      },
    };
  }

  public static setSearchText(searchText: Optional<string>): ISetSearchTextFilter {
    return {
      type: RobotListActions.SET_SEARCH_TEXT_FILTER,
      payload: {
        searchText,
      },
    };
  }

  public static setActiveSortField(field: Optional<string>): ISetActiveSortField {
    return {
      type: RobotListActions.SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setActiveSortOrder(order: Optional<SortOrders>): ISetActiveSortOrder {
    return {
      type: RobotListActions.SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getMachineListCoordinatesRequest({
    filter,
  }: IGetMachineListCoordinatesRequestOptions): IGetMachineListCoordinatesRequestAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_COORDINATES_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getMachineListCoordinatesSuccess(
    data: IGetMachineListCoordinatesSuccessOptions
  ): IGetMachineListCoordinatesSuccessAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_COORDINATES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListCoordinatesPicturesSuccess(
    data: IGetMachineListCoordinatesPicturesSuccessOptions
  ): IGetMachineListCoordinatesPicturesSuccessAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListCoordinatesError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineListCoordinatesErrorAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_COORDINATES_ERROR,
      error,
    };
  }

  public static requestExportMachinesRequest({
    filter,
    sortOptions,
    timezone,
    lang,
  }: IRequestExportMachinesRequestOptions): IRequestExportMachinesRequestAction {
    return {
      type: RobotListActions.REQUEST_EXPORT_MACHINES_REQUEST,
      payload: {
        filter,
        sortOptions,
        timezone,
        lang,
      },
    };
  }

  public static requestExportMachinesSuccess(
    data: IRequestExportMachinesSuccessOptions
  ): IRequestExportMachinesSuccessAction {
    return {
      type: RobotListActions.REQUEST_EXPORT_MACHINES_SUCCESS,
      payload: data,
    };
  }

  public static requestExportMachinesError({ error }: IActionCreatorErrorOptions): IRequestExportMachinesErrorAction {
    return {
      type: RobotListActions.REQUEST_EXPORT_MACHINES_ERROR,
      error,
    };
  }

  public static pollGetExportMachinesFileRequest({
    requestId,
  }: IPollGetExportMachinesFileRequestOptions): IPollGetExportMachinesFileRequestAction {
    return {
      type: RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_REQUEST,
      payload: {
        requestId,
      },
    };
  }

  public static pollGetExportMachinesFileSuccess(
    data: IPollGetExportMachinesFileSuccessOptions
  ): IPollGetExportMachinesFileSuccessAction {
    return {
      type: RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_SUCCESS,
      payload: data,
    };
  }

  public static pollGetExportMachinesFileError({
    error,
  }: IActionCreatorErrorOptions): IPollGetExportMachinesFileErrorAction {
    return {
      type: RobotListActions.POLL_GET_EXPORT_MACHINES_FILE_ERROR,
      error,
    };
  }

  public static exportResetState(): Action<typeof RobotListActions.EXPORT_RESET_STATE> {
    return {
      type: RobotListActions.EXPORT_RESET_STATE,
    };
  }

  public static getMachineListWithoutImageRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetMachineListRequestOptions): IGetMachineListRequestAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getMachineListWithoutImageSuccess(data: IGetMachineListSuccessOptions): IGetMachineListSuccessAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListWithoutImageError({ error }: IActionCreatorErrorOptions): IGetMachineListErrorAction {
    return {
      type: RobotListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR,
      error,
    };
  }
}
