import { Machine } from '../../interfaces/Machine.types';
import { IMachineAttachment } from '../../interfaces/MachineAttachment.types';
import { Optional } from 'lib/types/Optional';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const selectIsEditMachineNameModalVisible = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.editMachineName.visible;

export const selectIsEditMachineNameModalLoading = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.editMachineName.isLoading;

export const selectIsAddMachineAttachmentModalVisible = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.addMachineAttachment.visible;

export const selectIsAddMachineAttachmentModalLoading = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.addMachineAttachment.isLoading;

export const selectAddMachineAttachmentModalIsFileNameExisting = (state: IState): Optional<boolean> =>
  state.modules['machine-inventory'].modals.addMachineAttachment.isFileNameExisting;

export const selectDeleteMachineAttachmentMachine = (state: IState): Optional<Machine> =>
  state.modules['machine-inventory'].modals.deleteMachineAttachment.machine;

export const selectDeleteMachineAttachment = (state: IState): Optional<IMachineAttachment> =>
  state.modules['machine-inventory'].modals.deleteMachineAttachment.attachment;

export const selectIsDeleteMachineAttachmentModalVisible = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.deleteMachineAttachment.visible;

export const selectIsDeleteMachineAttachmentModalLoading = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.deleteMachineAttachment.isLoading;

export const selectIsEditMachineInfoModalVisible = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.editMachineInfo.visible;

export const selectIsEditMachineInfoModalLoading = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.editMachineInfo.isLoading;

export const selectEditMachineInfoSelectsIsSitesLoading = (state: IState): boolean =>
  state.modules['machine-inventory'].modals.editMachineInfo.selects.isLoading;

export const selectEditMachineInfoSelectsSites = (state: IState): Site[] =>
  state.modules['machine-inventory'].modals.editMachineInfo.selects.sites;
