import { useSelector } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { AxisOptions, Chart } from 'react-charts';
import { useTheme } from 'styled-components';
import { useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Flex, Spin } from 'antd';
import * as machineListSelectors from '../../state/machineListSelectors';
import { MachineListTabGraphsTooltip } from '../MachineListTabGraphsTooltip/MachineListTabGraphsTooltip';
import { StyledMachineListTabGraphs } from './MachineListTabGraphs.styles';
import { MachineListTabGraphsGlobalStyles } from './MachineListTabGraphs.global.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { ReactChartsData, ReactChartsDatum, ReactChartUtils } from 'app/utils/react-charts/ReactChartUtils';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { MachineClassification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const MachineListTabGraphs = ({ period }: { period: { startAt: string; endAt: string } }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const hasAccessToGCD = useSelector(authenticationSelectors.selectHasAccessToGCD);
  const hasAccessToRobots = useSelector(authenticationSelectors.selectHasAccessToRobots);
  const machineListData = useSelector(machineListSelectors.selectData);
  const machines = useMemo(
    () => (machineListData || []).filter(machine => machine?.classification !== MachineClassification.Robot),
    [machineListData]
  );
  const isLoading = !!useSelector(machineListSelectors.selectIsLoading);
  const chartDataChunkIndex = useRef(0);
  const data = ReactChartUtils.convertOperatingHoursToChartData(
    machines.map(machine => {
      const datum = {
        id: machine.machineId,
        name: machine.metadata?.name || machine.machineId,
        operatingTimeForPeriod: {
          actualTotalOperatingTimeMs: machine.statistics?.actualOperatingTimeInMs || 0,
          plannedTotalOperatingTimeMs: machine.statistics?.plannedOperatingTimeInMs || 0,
        },
      };
      return datum;
    })
  );

  let chunkData = ReactChartUtils.chunkChartData(data);
  if (!hasAccessToGCD) {
    chunkData = chunkData.map(chunk => [chunk[0]]);
  }
  const [chartData, setChartData] = useState<ReactChartsData[]>(chunkData[chartDataChunkIndex.current]);
  const [prevData, setPrevData] = useState(chunkData);

  if (!isEqual(prevData, chunkData)) {
    setPrevData(chunkData);
    setChartData(chunkData[chartDataChunkIndex.current]);
  }

  const onClickPrevBtn = (): void => {
    chartDataChunkIndex.current -= 1;
    setChartData(chunkData[chartDataChunkIndex.current]);
  };

  const onClickNextBtn = (): void => {
    chartDataChunkIndex.current += 1;
    setChartData(chunkData[chartDataChunkIndex.current]);
  };

  const primaryAxis = useMemo<AxisOptions<ReactChartsDatum>>(
    () => ({
      getValue: (datum): string => datum.primary,
      showGrid: false,
      formatters: {
        scale: (value: string): string => ReactChartUtils.extractColumnName(value),
      },
    }),
    []
  );

  const secondaryAxes = useMemo<AxisOptions<ReactChartsDatum>[]>(
    () => [
      {
        getValue: (datum): number => datum.secondary,
        min: 0,
        formatters: {
          scale: (value: number): string =>
            value ? DateTime.formatDurationByMilliseconds({ ms: value, showSeconds: false }) : '0',
          cursor: (value: number): string =>
            `${value ? DateTime.formatDurationByMilliseconds({ ms: value, showSeconds: false }) : '0'} ${t(
              'operatingHoursComparisonChart.hUnit'
            )}`,
        },
      },
    ],
    [t]
  );

  return (
    <StyledMachineListTabGraphs className="machine-report-chart">
      {hasAccessToRobots && (
        <Alert
          className="machine-report-chart__warning-message"
          message={t('operatingHoursComparisonChart.gcdOnlyWarningMessage')}
          type="info"
          showIcon
          closable
        />
      )}
      <Spin spinning={isLoading}>
        <div className="machine-report-chart__body">
          <MachineListTabGraphsGlobalStyles />
          <div className="machine-report-chart__legend">
            <div className="machine-report-chart__chart-note">
              <div className="machine-report-chart__chart-note-left">
                <div className="machine-report-chart__chart-note-square-left" />
                <p className="machine-report-chart__chart-note-text">
                  {t('operatingHoursComparisonChart.legend.actual')}
                </p>
              </div>
              {hasAccessToGCD && (
                <div className="machine-report-chart__chart-note-right">
                  <div className="machine-report-chart__chart-note-square-right" />
                  <p className="machine-report-chart__chart-note-text">
                    {t('operatingHoursComparisonChart.legend.planned')}
                  </p>
                </div>
              )}
            </div>
          </div>

          <p className="machine-report-chart__hours-unit">{t('operatingHoursComparisonChart.hrsUnit')}</p>

          <div className="machine-report-chart__chart">
            <Flex align="flex-end" className="machine-report-chart__chart-arrow">
              {chartDataChunkIndex.current !== 0 && (
                <Button
                  type="text"
                  size="middle"
                  icon={<LeftOutlined className="machine-report-chart__chart-arrow-icon" />}
                  onClick={onClickPrevBtn}
                  className="machine-report-chart__chart-arrow-btn"
                />
              )}
            </Flex>

            <div className="machine-report-chart__bar-stacked-chart">
              <div className="machine-report-chart__bar-stacked-chart-wrapper">
                <Chart
                  options={{
                    data: chartData,
                    primaryAxis,
                    secondaryAxes,
                    defaultColors: [theme.colors.celadonGreen, theme.colors.lightCeladonGreen],
                    tooltip: {
                      render: (props): React.ReactNode =>
                        MachineListTabGraphsTooltip(period.startAt, period.endAt, t, i18n, theme, props),
                    },
                  }}
                />
              </div>
            </div>

            <Flex align="flex-end" className="machine-report-chart__chart-arrow">
              {chartDataChunkIndex.current !== chunkData.length - 1 && (
                <Button
                  type="text"
                  size="middle"
                  icon={<RightOutlined className="machine-report-chart__chart-arrow-icon" />}
                  onClick={onClickNextBtn}
                  className="machine-report-chart__chart-arrow-btn"
                />
              )}
            </Flex>
          </div>
        </div>
      </Spin>
    </StyledMachineListTabGraphs>
  );
};
