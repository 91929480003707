import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyPrimaryButton } from '../../../../../../lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from '../../../../../../lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { LegacyInput } from '../../../../../../lib/components/LegacyInput/LegacyInput';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { AnalyticsForm } from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Machine } from '../../../interfaces/Machine.types';
import {
  selectIsEditMachineNameModalLoading,
  selectIsEditMachineNameModalVisible,
} from '../../state/machineModalsSelectors';
import { MachineModalsActions } from '../../state/machineModalsSlice';
import { StyledEditMachineNameModal } from './EditMachineNameModal.styles';
import { MachineDetailsActions } from 'app/modules/machine-inventory/machine-details/state/machineDetailsActions';

export interface EditMachineNameModalProps {
  machine: Machine;
}

export const EditMachineNameModal = ({ machine }: EditMachineNameModalProps): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectIsEditMachineNameModalVisible);
  const isLoading = useSelector(selectIsEditMachineNameModalLoading);

  const [machineName, setMachineName] = useState<string>(machine?.name ?? '');

  useEffect(() => {
    // Reset input state when closing
    setMachineName(machine?.name ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMachineName(e.target.value);
    },
    [setMachineName]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.EDIT_MACHINE_NAME,
    fields: {
      machineName,
    },
    isVisible,
  });

  const handleCancel = useCallback(() => {
    dispatch(MachineModalsActions.hideEditMachineNameModal());
  }, [dispatch]);

  const handleOk = useCallback(() => {
    dispatch(
      MachineDetailsActions.updateMachineRequest({
        id: machine.id,
        name: machineName,
      })
    );
  }, [dispatch, machine, machineName]);

  return (
    <StyledEditMachineNameModal
      className="edit-machine-name-modal"
      title={t('machineModals.editMachineName.title')}
      open={isVisible}
      destroyOnClose={true}
      footer={[
        <LegacySecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('common.save')}
        </LegacyPrimaryButton>,
      ]}
    >
      <h3 className="input-label">{t('machineModals.editMachineName.nameLabel')}</h3>
      <LegacyInput value={machineName} onChange={handleChange} />
    </StyledEditMachineNameModal>
  );
};
