import { produce } from 'immer';
import { isNil } from 'lodash-es';
import { AnyAction } from 'redux';
import { Optional } from '../../../../../lib/types/Optional';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Machine } from '../../interfaces/Machine.types';
import { Reminder } from '../../../reminder/interfaces/Reminder.types';
import {
  DEFAULT_MACHINE_NOTIFICATIONS_PAGE_SIZE_VALUE,
  MACHINE_ATTACHMENT_LIST_PAGE_SIZE_LARGE,
  MACHINE_REMINDER_LIST_PAGE_SIZE,
} from '../../utils/constants';
import { MachineUtils } from '../../utils/MachineUtils';
import { IMachineAttachment, MachineAttachmentsListResponse } from '../../interfaces/MachineAttachment.types';
import { MachineDetailsActions } from './machineDetailsActions';
import {
  Notification,
  SortOrders,
  OperatingTimePeriod,
  MachineNote,
  Maybe,
  RobotTaskCompletionStatisticData,
  CleaningTaskReport,
  RobotCleaningConsumption,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import { RobotUtils } from 'app/utils/robot/RobotUtils';

export interface MachineDetailsState {
  data: Optional<Machine>;
  isLoading: Optional<boolean>;
  isPictureLoading: boolean;
  isTelemetryLoading: boolean;
  isLatestCtrLoading: boolean;
  notifications: {
    data: Optional<Notification[]>;
    isLoading: Optional<boolean>;
    totalCount: Optional<number>;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    sortOptions: {
      field: Optional<string>;
      order: Optional<SortOrders>;
    };
  };
  operatingTime: {
    data: Optional<OperatingTimePeriod>;
    isLoading: Optional<boolean>;
  };
  reminders: {
    data: Optional<Reminder[]>;
    isInitialLoad: Optional<boolean>;
    isLoadingMore: Optional<boolean>;
    totalCount: Optional<number>;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
  };
  notes: {
    data: Optional<MachineNote[]>;
    isInitialLoad: Optional<boolean>;
    isLoadingMore: Optional<boolean>;
    totalCount: Optional<number>;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
  };
  attachments: {
    data: Optional<IMachineAttachment[]>;
    isLoading: Optional<boolean>;
    totalCount: Optional<number>;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    sortOptions: {
      field: Maybe<string>;
      order: Maybe<SortOrders>;
    };
    downloads: {
      isLoading: Optional<boolean>;
    };
  };
  taskCompletionHistory: {
    isLoading: Optional<boolean>;
    data: Optional<RobotTaskCompletionStatisticData>;
  };
  cleaningTaskReports: {
    data: Optional<CleaningTaskReport[]>;
    latestData: Optional<CleaningTaskReport>;
    isInitialLoad: Optional<boolean>;
    isLoadingMore: Optional<boolean>;
    totalCount: Optional<number>;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    routeImage: {
      url: Optional<string>;
      imageObjectUrl: Optional<string>;
      isLoading: Optional<boolean>;
    };
  };
  cleaningConsumptionSummary: {
    data: Optional<RobotCleaningConsumption>;
    isLoading: Optional<boolean>;
  };
}

export const initialState: MachineDetailsState = {
  data: null,
  isLoading: null,
  isPictureLoading: true,
  isTelemetryLoading: true,
  isLatestCtrLoading: true,
  notifications: {
    data: null,
    totalCount: null,
    isLoading: null,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_MACHINE_NOTIFICATIONS_PAGE_SIZE_VALUE,
    sortOptions: {
      field: null,
      order: null,
    },
  },
  operatingTime: {
    data: null,
    isLoading: null,
  },
  reminders: {
    data: null,
    isInitialLoad: null,
    isLoadingMore: null,
    totalCount: null,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: MACHINE_REMINDER_LIST_PAGE_SIZE,
  },
  notes: {
    data: null,
    isInitialLoad: null,
    isLoadingMore: null,
    totalCount: null,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: MACHINE_REMINDER_LIST_PAGE_SIZE,
  },
  attachments: {
    data: null,
    totalCount: null,
    isLoading: null,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: MACHINE_ATTACHMENT_LIST_PAGE_SIZE_LARGE,
    sortOptions: {
      field: null,
      order: null,
    },
    downloads: {
      isLoading: null,
    },
  },
  taskCompletionHistory: {
    data: null,
    isLoading: null,
  },
  cleaningTaskReports: {
    data: null,
    latestData: null,
    isInitialLoad: null,
    isLoadingMore: null,
    totalCount: null,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
    routeImage: {
      url: null,
      imageObjectUrl: null,
      isLoading: null,
    },
  },
  cleaningConsumptionSummary: {
    data: null,
    isLoading: null,
  },
};

export const machineDetailsReducer = (state = initialState, action: AnyAction): MachineDetailsState =>
  produce(state, draft => {
    switch (action.type) {
      case MachineDetailsActions.GET_MACHINE_DETAILS_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_DETAILS_SUCCESS: {
        const {
          machine: { data },
        } = action.payload;

        draft.isLoading = false;
        draft.data = data;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_DETAILS_PICTURE_SUCCESS: {
        const {
          machine: { data: machineVariantDatum },
        } = action.payload;

        draft.isPictureLoading = false;

        if (!isNil(draft.data)) {
          draft.data = MachineUtils.mergeMachineWithVariantData(draft.data, [machineVariantDatum]);
        }

        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS: {
        const {
          machine: { data: machineTelemetriesDatum },
        } = action.payload;

        draft.isTelemetryLoading = false;

        if (!isNil(draft.data)) {
          draft.data = MachineUtils.mergeMachineWithTelemetryData(draft.data, [machineTelemetriesDatum]);
        }

        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS: {
        const {
          machine: { data: machineLatestCtrDatum },
        } = action.payload;

        draft.isLatestCtrLoading = false;

        if (!isNil(draft.data)) {
          draft.data = MachineUtils.mergeMachineWithLatestCtrData(draft.data, [machineLatestCtrDatum]);
        }

        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_DETAILS_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_OPERATING_TIME_REQUEST: {
        draft.operatingTime.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_OPERATING_TIME_SUCCESS: {
        const {
          machine: {
            data: { operatingTimeForPeriod },
          },
        } = action.payload;

        draft.operatingTime.isLoading = false;
        draft.operatingTime.data = operatingTimeForPeriod;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_OPERATING_TIME_ERROR: {
        draft.operatingTime.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_REMINDERS_REQUEST: {
        const { append } = action.payload;

        if (append) {
          draft.reminders.isLoadingMore = true;
        } else {
          draft.reminders.isInitialLoad = true;
        }

        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_REMINDERS_SUCCESS: {
        const {
          machine: {
            data: {
              reminders: {
                data,
                metadata: { totalCount, paginationToken },
              },
            },
          },
          append,
        } = action.payload;

        if (append) {
          draft.reminders.isLoadingMore = false;
          draft.reminders.page += 1;
          draft.reminders.data = (draft.reminders.data ?? []).concat(data);
        } else {
          draft.reminders.isInitialLoad = false;
          draft.reminders.page = 1;
          draft.reminders.data = data;
        }

        draft.reminders.totalCount = totalCount;
        if (paginationToken) {
          draft.reminders.paginationTokens[draft.reminders.page + 1] = paginationToken;
        }
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_REMINDERS_ERROR: {
        draft.reminders.isInitialLoad = false;
        draft.reminders.isLoadingMore = false;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_NOTES_REQUEST: {
        const { append } = action.payload;

        if (append) {
          draft.notes.isLoadingMore = true;
        } else {
          draft.notes.isInitialLoad = true;
        }

        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_NOTES_SUCCESS: {
        const {
          machine: {
            data: {
              notes: {
                data,
                metadata: { totalCount, paginationToken },
              },
            },
          },
          append,
        } = action.payload;

        if (append) {
          draft.notes.isLoadingMore = false;
          draft.notes.page += 1;
          draft.notes.data = (draft.notes.data ?? []).concat(data);
        } else {
          draft.notes.isInitialLoad = false;
          draft.notes.page = 1;
          draft.notes.data = data;
        }

        draft.notes.totalCount = totalCount;
        if (paginationToken) {
          draft.notes.paginationTokens[draft.notes.page + 1] = paginationToken;
        }
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_NOTES_ERROR: {
        draft.notes.isInitialLoad = false;
        draft.notes.isLoadingMore = false;
        return draft;
      }

      case MachineDetailsActions.UPDATE_MACHINE_SUCCESS: {
        const {
          machineUpdate: { data },
        } = action.payload;

        if (!isNil(draft.data)) {
          draft.data.name = data.name;
        }

        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_REQUEST: {
        draft.notifications.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_SUCCESS: {
        const {
          notifications: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;
        draft.notifications.isLoading = false;
        draft.notifications.data = data;
        draft.notifications.totalCount = totalCount;
        if (paginationToken) {
          draft.notifications.paginationTokens[draft.notifications.page + 1] = paginationToken;
        }
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_NOTIFICATION_LIST_ERROR: {
        draft.notifications.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.CHANGE_NOTIFICATIONS_TABLE_PAGE: {
        const { page } = action.payload;
        draft.notifications.page = page;
        return draft;
      }

      case MachineDetailsActions.SET_ACTIVE_NOTIFICATIONS_SORT_FIELD: {
        const { field } = action.payload;
        draft.notifications.sortOptions.field = field;
        return draft;
      }

      case MachineDetailsActions.SET_ACTIVE_NOTIFICATIONS_SORT_ORDER: {
        const { order } = action.payload;
        draft.notifications.sortOptions.order = order;
        return draft;
      }

      case MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_REQUEST: {
        draft.attachments.isLoading = true;
        return draft;
      }
      case MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_SUCCESS: {
        const {
          machine: {
            data: {
              attachments: { data, metadata },
            },
          },
        } = action.payload as MachineAttachmentsListResponse;
        draft.attachments.isLoading = false;
        draft.attachments.data = data.map(datum => ({
          id: datum.id,
          fileName: datum.name,
          mimeType: datum.mimeType,
          createdAt: datum.createdAt,
        }));

        draft.attachments.totalCount = metadata?.totalCount;
        if (metadata?.paginationToken) {
          draft.attachments.paginationTokens[draft.attachments.page + 1] = metadata?.paginationToken;
        }
        return draft;
      }
      case MachineDetailsActions.GET_MACHINE_ATTACHMENTS_LIST_ERROR: {
        draft.attachments.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.MACHINE_ATTACHMENTS_IS_LOADING: {
        draft.attachments.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE: {
        const { page } = action.payload;
        draft.attachments.page = page;
        return draft;
      }

      case MachineDetailsActions.SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.attachments.sortOptions.order = order;
        draft.attachments.paginationTokens = {};
        draft.attachments.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_REQUEST: {
        draft.attachments.downloads.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS: {
        draft.attachments.downloads.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.DOWNLOAD_MACHINE_ATTACHMENT_ERROR: {
        draft.attachments.downloads.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_REQUEST: {
        draft.taskCompletionHistory.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_SUCCESS: {
        const {
          data: {
            robotTaskCompletionStatistic: { data },
          },
        } = action.payload;

        draft.taskCompletionHistory.isLoading = false;
        draft.taskCompletionHistory.data = data;
        return draft;
      }

      case MachineDetailsActions.GET_TASK_COMPLETION_HISTORY_ERROR: {
        draft.taskCompletionHistory.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.RESET_STATE: {
        return initialState;
      }

      case MachineDetailsActions.GET_CTR_LIST_ROBOT_REQUEST: {
        const { append } = action.payload;

        if (append) {
          draft.cleaningTaskReports.isLoadingMore = true;
        } else {
          draft.cleaningTaskReports.isInitialLoad = true;
        }
        return draft;
      }

      case MachineDetailsActions.GET_CTR_LIST_ROBOT_SUCCESS: {
        const {
          cleaningTaskReports: {
            data,
            metadata: { totalCount, paginationToken },
          },
          append,
        } = action.payload;

        if (append) {
          draft.cleaningTaskReports.isLoadingMore = false;
          draft.cleaningTaskReports.page += 1;
          draft.cleaningTaskReports.data = (draft.cleaningTaskReports.data ?? []).concat(data);
        } else {
          draft.cleaningTaskReports.isInitialLoad = false;
          draft.cleaningTaskReports.page = 1;
          draft.cleaningTaskReports.data = data;
        }

        draft.cleaningTaskReports.latestData = draft.cleaningTaskReports.data?.[0];

        draft.cleaningTaskReports.totalCount = totalCount;
        if (paginationToken) {
          draft.cleaningTaskReports.paginationTokens[draft.cleaningTaskReports.page + 1] = paginationToken;
        }
        return draft;
      }

      case MachineDetailsActions.GET_CTR_LIST_ROBOT_ERROR: {
        draft.cleaningTaskReports.isInitialLoad = false;
        draft.cleaningTaskReports.isLoadingMore = false;
        return draft;
      }

      case MachineDetailsActions.RESET_ROBOT_CTR_LIST_STATE: {
        draft.cleaningTaskReports = initialState.cleaningTaskReports;
        return draft;
      }

      case MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST: {
        draft.cleaningTaskReports.routeImage.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS: {
        const { url, imageObjectUrl } = action.payload;
        draft.cleaningTaskReports.routeImage.isLoading = false;
        if (!url && !imageObjectUrl) {
          draft.cleaningTaskReports.routeImage.url = initialState.cleaningTaskReports.routeImage.url;
          draft.cleaningTaskReports.routeImage.imageObjectUrl =
            initialState.cleaningTaskReports.routeImage.imageObjectUrl;
          return draft;
        }
        draft.cleaningTaskReports.routeImage.url = url;
        draft.cleaningTaskReports.routeImage.imageObjectUrl = imageObjectUrl;
        return draft;
      }

      case MachineDetailsActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR: {
        draft.cleaningTaskReports.routeImage.url = initialState.cleaningTaskReports.routeImage.url;
        draft.cleaningTaskReports.routeImage.imageObjectUrl =
          initialState.cleaningTaskReports.routeImage.imageObjectUrl;
        draft.cleaningTaskReports.routeImage.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE: {
        draft.cleaningTaskReports.routeImage = initialState.cleaningTaskReports.routeImage;
        return draft;
      }

      case MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_REQUEST: {
        draft.cleaningConsumptionSummary.isLoading = true;
        return draft;
      }

      case MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_SUCCESS: {
        const robotConsumptionSummary = action.payload?.robotConsumptionSummary;
        draft.cleaningConsumptionSummary.isLoading = false;
        draft.cleaningConsumptionSummary.data = robotConsumptionSummary?.data || null;
        return draft;
      }

      case MachineDetailsActions.GET_CONSUMPTIONS_SUMMARY_ERROR: {
        draft.cleaningConsumptionSummary.isLoading = false;
        return draft;
      }

      case MachineDetailsActions.MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED: {
        if (draft.data && action.payload.updatedData) {
          draft.data = RobotUtils.getRobotPropertiesUpdated(draft.data, action.payload.updatedData);

          return draft;
        }

        return draft;
      }

      default:
        return draft;
    }
  });
