import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledMachineListReportSubscriptionDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: ${(props): string => props.theme.spacing.lg};
  }

  .report-subscription-drawer__header {
    flex-grow: 0;
  }

  .report-subscription-drawer__title {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
    text-transform: uppercase;
  }

  .report-subscription-drawer__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${(props): string => props.theme.spacing.xs};
    overflow: auto;
  }

  .report-subscription-drawer__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
  }

  .report-subscription-drawer__close-button,
  .report-subscription-drawer__save-button {
    &.ant-btn.button-base.button__size-m {
      ${(props): string => props.theme.fontStyles.redesign.base.delete};
    }
  }
`;
