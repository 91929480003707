import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledAddMachineAttachmentModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 0;
  }

  .edit-machine-attachment-modal__name-warning {
    margin-bottom: 16px;
  }
`;
