import { differenceInHours } from 'date-fns';
import { isString } from 'lodash-es';
import { Optional } from '../../../../lib/types/Optional';
import {
  ILegacyMachineStatusItemData,
  Machine,
  LegacyMachineStatus,
  LegacyMachineStatusItemData,
} from '../interfaces/Machine.types';

export const getLegacyMachineStatus = (machine: Machine): Optional<ILegacyMachineStatusItemData> => {
  let hoursSinceLastActivity;

  // Statuses MachineClassification.DEACTIVATED and MachineClassification.ARCHIVED will be implemented in the future

  if (machine.isIoTDevice === false) {
    return LegacyMachineStatusItemData[LegacyMachineStatus.NON_IOT];
  }

  if (isString(machine?.lastActivityAt)) {
    // Use the machine's lastActivityAt value
    hoursSinceLastActivity = differenceInHours(new Date(), new Date(machine?.lastActivityAt || ''));
  } else {
    // lastActivityAt has nil value, so for the purpose of the machine  status we consider it
    // to be 1970-01-01T00:00:00.000Z
    hoursSinceLastActivity = differenceInHours(new Date(), new Date(0));
  }

  if (hoursSinceLastActivity >= 72) {
    return LegacyMachineStatusItemData[LegacyMachineStatus.DATA_AGE_OVER_THREE_DAYS];
  }

  if (hoursSinceLastActivity >= 24 && hoursSinceLastActivity < 72) {
    return LegacyMachineStatusItemData[LegacyMachineStatus.DATA_AGE_ONE_TO_THREE_DAYS];
  }

  if (hoursSinceLastActivity < 24) {
    return LegacyMachineStatusItemData[LegacyMachineStatus.DATA_AGE_LESS_THAN_ONE_DAY];
  }

  return null;
};
