import { Action } from 'redux';
import { InputPeriod, SortOrders } from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  MachinesExportRequest,
  MachineList,
  MachineListAvailableFilters,
  MachineListVariantData,
  MachinesExportGetFile,
  MachineListAsReport,
  MachinesReportExportRequest,
  MachineReportSubscriptionsListResponse,
  MachineReportSubscriptionsResetResponse,
  MachineFilterResponse,
} from '../../interfaces/Machine.types';
import {
  MachineClientRequestExportMachinesOptions,
  MachineClientListCoordinatesOptions,
  MachineClientGetExportMachinesFileOptions,
  MachineClientRequestExportMachinesReportOptions,
  MachineClientResetMachineReportSubscriptionsOptions,
  MachineClientMachineFilterOptions,
} from '../../MachineClient';
import { MachineServiceListAsReportOptions } from '../../MachineService';
import { Optional } from 'lib/types/Optional';

export type IGetMachineListAsReportRequestOptions = MachineServiceListAsReportOptions;
export type IGetMachineListAsReportRequestAction = Action<
  typeof MachineListActions.GET_MACHINE_LIST_AS_REPORT_REQUEST
> & {
  payload: MachineServiceListAsReportOptions;
};

export type IGetMachineListAsReportSuccessOptions = MachineListAsReport;
export type IGetMachineListAsReportSuccessAction = Action<
  typeof MachineListActions.GET_MACHINE_LIST_AS_REPORT_SUCCESS
> & {
  payload: MachineListAsReport;
};

export type IGetMachineListAsReportErrorAction = IErrorAction<
  typeof MachineListActions.GET_MACHINE_LIST_AS_REPORT_ERROR
>;

export type IChangeMachineListTablePage = Action<typeof MachineListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeMachineListTablePageSize = Action<typeof MachineListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type IGetMachineListFiltersRequestAction = Action<typeof MachineListActions.GET_MACHINE_LIST_FILTERS_REQUEST>;

export type IGetMachineListFiltersSuccessOptions = MachineListAvailableFilters;
export type IGetMachineListFiltersSuccessAction = Action<typeof MachineListActions.GET_MACHINE_LIST_FILTERS_REQUEST> & {
  payload: MachineListAvailableFilters;
};

export type IGetMachineListFiltersErrorAction = IErrorAction<typeof MachineListActions.GET_MACHINE_LIST_FILTERS_ERROR>;

export type ISetActiveMaterialNumberFilter = Action<typeof MachineListActions.SET_ACTIVE_MATERIAL_NUMBER_FILTER> & {
  payload: {
    materialNumbers: Optional<string[]>;
  };
};

export type ISetActiveSiteFilter = Action<typeof MachineListActions.SET_ACTIVE_SITE_FILTER> & {
  payload: {
    sites: string[];
  };
};

export type ISetActiveMachineTypeFilter = Action<typeof MachineListActions.SET_ACTIVE_MACHINE_TYPE_FILTER> & {
  payload: {
    machineTypes: Optional<string[]>;
  };
};

export type ISetActiveMachineStatusFilter = Action<typeof MachineListActions.SET_ACTIVE_MACHINE_STATUS_FILTER> & {
  payload: {
    machineStatus: Optional<string>;
  };
};

export type ISetActiveMachinePeriodFilter = Action<typeof MachineListActions.SET_ACTIVE_MACHINE_PERIOD_FILTER> & {
  payload: {
    period: InputPeriod;
  };
};

export type ISetSearchTextFilter = Action<typeof MachineListActions.SET_SEARCH_TEXT_FILTER> & {
  payload: {
    searchText: Optional<string>;
  };
};

export type ISetActiveSortField = Action<typeof MachineListActions.SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveSortOrder = Action<typeof MachineListActions.SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IGetMachineListCoordinatesRequestOptions = MachineClientListCoordinatesOptions;
export type IGetMachineListCoordinatesRequestAction = Action<
  typeof MachineListActions.GET_MACHINE_LIST_COORDINATES_REQUEST
> & {
  payload: MachineClientListCoordinatesOptions;
};

export type IGetMachineListCoordinatesSuccessOptions = MachineList;
export type IGetMachineListCoordinatesSuccessAction = Action<
  typeof MachineListActions.GET_MACHINE_LIST_COORDINATES_REQUEST
> & {
  payload: MachineList;
};

export type IGetMachineListCoordinatesPicturesSuccessOptions = MachineListVariantData;
export type IGetMachineListCoordinatesPicturesSuccessAction = Action<
  typeof MachineListActions.GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS
> & {
  payload: MachineListVariantData;
};

export type IGetMachineListCoordinatesErrorAction = IErrorAction<
  typeof MachineListActions.GET_MACHINE_LIST_COORDINATES_ERROR
>;

export type IRequestExportMachinesRequestOptions = MachineClientRequestExportMachinesOptions;
export type IRequestExportMachinesRequestAction = Action<typeof MachineListActions.REQUEST_EXPORT_MACHINES_REQUEST> & {
  payload: MachineClientRequestExportMachinesOptions;
};

export type IRequestExportMachinesSuccessOptions = Optional<MachinesExportRequest>;
export type IRequestExportMachinesSuccessAction = Action<typeof MachineListActions.REQUEST_EXPORT_MACHINES_SUCCESS> & {
  payload: Optional<MachinesExportRequest>;
};

export type IRequestExportMachinesErrorAction = IErrorAction<typeof MachineListActions.REQUEST_EXPORT_MACHINES_ERROR>;

export type IRequestExportMachinesReportRequestOptions = MachineClientRequestExportMachinesReportOptions;
export type IRequestExportMachinesReportRequestAction = Action<
  typeof MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_REQUEST
> & {
  payload: MachineClientRequestExportMachinesReportOptions;
};

export type IRequestExportMachinesReportSuccessOptions = Optional<MachinesReportExportRequest>;
export type IRequestExportMachinesReportSuccessAction = Action<
  typeof MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_SUCCESS
> & {
  payload: Optional<MachinesReportExportRequest>;
};

export type IRequestExportMachinesReportErrorAction = IErrorAction<
  typeof MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_ERROR
>;

export type IPollGetExportMachinesFileRequestOptions = MachineClientGetExportMachinesFileOptions;
export type IPollGetExportMachinesFileRequestAction = Action<
  typeof MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_REQUEST
> & {
  payload: IPollGetExportMachinesFileRequestOptions;
};

export type IPollGetExportMachinesFileSuccessOptions = MachinesExportGetFile;
export type IPollGetExportMachinesFileSuccessAction = Action<
  typeof MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_SUCCESS
> & {
  payload: MachinesExportGetFile;
};

export type IPollGetExportMachinesFileErrorAction = IErrorAction<
  typeof MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_ERROR
>;

export type IPollGetMachineListAsReportRequestAction = Action<
  typeof MachineListActions.POLL_GET_MACHINE_LIST_AS_REPORT_REQUEST
>;

export type IPollGetMachineListAsReportErrorAction = IErrorAction<
  typeof MachineListActions.POLL_GET_MACHINE_LIST_AS_REPORT_ERROR
>;

export type IShowMachineReportSubscriptionsDrawerAction = Action<
  typeof MachineListActions.SHOW_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER
>;

export type IHideMachineReportSubscriptionsDrawerAction = Action<
  typeof MachineListActions.HIDE_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER
>;

// LIST_MACHINE_REPORT_SUBSCRIPTIONS
export type IListMachineReportSubscriptionsRequestAction = Action<
  typeof MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST
>;

export type IListMachineReportSubscriptionsSuccessOptions = Optional<MachineReportSubscriptionsListResponse>;
export type IListMachineReportSubscriptionsSuccessAction = Action<
  typeof MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS
> & {
  payload: Optional<MachineReportSubscriptionsListResponse>;
};

export type IListMachineReportSubscriptionsErrorAction = IErrorAction<
  typeof MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_ERROR
>;

// RESET_MACHINE_REPORT_SUBSCRIPTIONS
export type IResetMachineReportSubscriptionsRequestOptions = MachineClientResetMachineReportSubscriptionsOptions;
export type IResetMachineReportSubscriptionsRequestAction = Action<
  typeof MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST
> & {
  payload: MachineClientResetMachineReportSubscriptionsOptions;
};

export type IResetMachineReportSubscriptionsSuccessOptions = Optional<MachineReportSubscriptionsResetResponse>;
export type IResetMachineReportSubscriptionsSuccessAction = Action<
  typeof MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS
> & {
  payload: Optional<MachineReportSubscriptionsResetResponse>;
};

export type IResetMachineReportSubscriptionsErrorAction = IErrorAction<
  typeof MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_ERROR
>;

export type IGetMachineFilterRequestOptions = MachineClientMachineFilterOptions;
export type IGetMachineFilterRequestAction = Action<typeof MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_REQUEST> & {
  payload: MachineClientMachineFilterOptions;
};

export type IGetMachineFilterSuccessOptions = Optional<MachineFilterResponse>;
export type IGetMachineFilterSuccessAction = Action<typeof MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_SUCCESS> & {
  payload: Optional<MachineFilterResponse>;
};

export type IGetMachineFilterErrorAction = IErrorAction<typeof MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_ERROR>;

export type IGetMachineFilterResetStateAction = Action<
  typeof MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_RESET_STATE
>;

export class MachineListActions {
  public static GET_MACHINE_LIST_AS_REPORT_REQUEST = 'MACHINE_LIST::GET_MACHINE_LIST_AS_REPORT_REQUEST';
  public static GET_MACHINE_LIST_AS_REPORT_SUCCESS = 'MACHINE_LIST::GET_MACHINE_LIST_AS_REPORT_SUCCESS';
  public static GET_MACHINE_LIST_AS_REPORT_ERROR = 'MACHINE_LIST::GET_MACHINE_LIST_AS_REPORT_ERROR';
  public static POLL_GET_MACHINE_LIST_AS_REPORT_REQUEST = 'MACHINE_LIST::POLL_GET_MACHINE_LIST_AS_REPORT_REQUEST';
  public static POLL_GET_MACHINE_LIST_AS_REPORT_ERROR = 'MACHINE_LIST::POLL_GET_MACHINE_LIST_AS_REPORT_ERROR';

  public static CHANGE_TABLE_PAGE = 'MACHINE_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'MACHINE_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static RESET_STATE = 'MACHINE_LIST::RESET_STATE';

  public static GET_MACHINE_LIST_FILTERS_REQUEST = 'MACHINE_LIST::GET_MACHINE_LIST_FILTERS_REQUEST';
  public static GET_MACHINE_LIST_FILTERS_SUCCESS = 'MACHINE_LIST::GET_MACHINE_LIST_FILTERS_SUCCESS';
  public static GET_MACHINE_LIST_FILTERS_ERROR = 'MACHINE_LIST::GET_MACHINE_LIST_FILTERS_ERROR';

  public static SET_ACTIVE_MATERIAL_NUMBER_FILTER = 'MACHINE_LIST::SET_ACTIVE_MATERIAL_NUMBER_FILTER';
  public static SET_ACTIVE_SITE_FILTER = 'MACHINE_LIST::SET_ACTIVE_SITE_FILTER';
  public static SET_ACTIVE_MACHINE_TYPE_FILTER = 'MACHINE_LIST::SET_ACTIVE_MACHINE_TYPE_FILTER';
  public static SET_ACTIVE_MACHINE_STATUS_FILTER = 'MACHINE_LIST::SET_ACTIVE_MACHINE_STATUS_FILTER';
  public static SET_ACTIVE_MACHINE_PERIOD_FILTER = 'MACHINE_LIST::SET_ACTIVE_MACHINE_PERIOD_FILTER';

  public static SET_SEARCH_TEXT_FILTER = 'MACHINE_LIST::SET_SEARCH_TEXT_FILTER';

  public static SET_ACTIVE_SORT_FIELD = 'MACHINE_LIST::SET_ACTIVE_SORT_FIELD';
  public static SET_ACTIVE_SORT_ORDER = 'MACHINE_LIST::SET_ACTIVE_SORT_ORDER';

  public static GET_MACHINE_LIST_COORDINATES_REQUEST = 'MACHINE_LIST::GET_MACHINE_LIST_COORDINATES_REQUEST';
  public static GET_MACHINE_LIST_COORDINATES_SUCCESS = 'MACHINE_LIST::GET_MACHINE_LIST_COORDINATES_SUCCESS';
  public static GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS =
    'MACHINE_LIST::GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS';

  public static GET_MACHINE_LIST_COORDINATES_ERROR = 'MACHINE_LIST::GET_MACHINE_LIST_COORDINATES_ERROR';

  public static REQUEST_EXPORT_MACHINES_REQUEST = 'MACHINE_LIST::REQUEST_EXPORT_MACHINES_REQUEST';
  public static REQUEST_EXPORT_MACHINES_SUCCESS = 'MACHINE_LIST::REQUEST_EXPORT_MACHINES_SUCCESS';
  public static REQUEST_EXPORT_MACHINES_ERROR = 'MACHINE_LIST::REQUEST_EXPORT_MACHINES_ERROR';

  public static REQUEST_EXPORT_MACHINES_REPORT_REQUEST = 'MACHINE_LIST::REQUEST_EXPORT_MACHINES_REPORT_REQUEST';
  public static REQUEST_EXPORT_MACHINES_REPORT_SUCCESS = 'MACHINE_LIST::REQUEST_EXPORT_MACHINES_REPORT_SUCCESS';
  public static REQUEST_EXPORT_MACHINES_REPORT_ERROR = 'MACHINE_LIST::REQUEST_EXPORT_MACHINES_REPORT_ERROR';

  public static POLL_GET_EXPORT_MACHINES_FILE_REQUEST = 'MACHINE_LIST::POLL_GET_EXPORT_MACHINES_FILE_REQUEST';
  public static POLL_GET_EXPORT_MACHINES_FILE_SUCCESS = 'MACHINE_LIST::POLL_GET_EXPORT_MACHINES_FILE_SUCCESS';
  public static POLL_GET_EXPORT_MACHINES_FILE_ERROR = 'MACHINE_LIST::POLL_GET_EXPORT_MACHINES_FILE_ERROR';
  public static EXPORT_RESET_STATE = 'MACHINE_LIST::EXPORT_RESET_STATE';

  public static SHOW_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER = 'MACHINE_LIST::SHOW_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER';
  public static HIDE_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER = 'MACHINE_LIST::HIDE_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER';

  public static LIST_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST = 'MACHINE_LIST::LIST_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST';
  public static LIST_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS = 'MACHINE_LIST::LIST_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS';
  public static LIST_MACHINE_REPORT_SUBSCRIPTIONS_ERROR = 'MACHINE_LIST::LIST_MACHINE_REPORT_SUBSCRIPTIONS_ERROR';

  public static RESET_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST = 'MACHINE_LIST::RESET_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST';
  public static RESET_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS = 'MACHINE_LIST::RESET_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS';
  public static RESET_MACHINE_REPORT_SUBSCRIPTIONS_ERROR = 'MACHINE_LIST::RESET_MACHINE_REPORT_SUBSCRIPTIONS_ERROR';

  public static GET_MACHINE_AVAILABLE_FILTERS_REQUEST = 'MACHINE_LIST:GET_MACHINE_AVAILABLE_FILTERS_REQUEST';
  public static GET_MACHINE_AVAILABLE_FILTERS_SUCCESS = 'MACHINE_LIST:GET_MACHINE_AVAILABLE_FILTERS_SUCCESS';
  public static GET_MACHINE_AVAILABLE_FILTERS_ERROR = 'MACHINE_LIST:GET_MACHINE_AVAILABLE_FILTERS_ERROR';
  public static GET_MACHINE_AVAILABLE_FILTERS_RESET_STATE = 'MACHINE_LIST:GET_MACHINE_AVAILABLE_FILTERS_RESET_STATE';

  public static getMachineListAsReportRequest({
    period,
    search,
    filter,
    sortOptions,
    paginationOptions,
    timezone,
  }: IGetMachineListAsReportRequestOptions): IGetMachineListAsReportRequestAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_AS_REPORT_REQUEST,
      payload: {
        period,
        search,
        filter,
        sortOptions,
        paginationOptions,
        timezone,
      },
    };
  }

  public static getMachineListAsReportSuccess(
    data: IGetMachineListAsReportSuccessOptions
  ): IGetMachineListAsReportSuccessAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_AS_REPORT_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListAsReportError({ error }: IActionCreatorErrorOptions): IGetMachineListAsReportErrorAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_AS_REPORT_ERROR,
      error,
    };
  }

  public static pollGetMachineListAsReportRequest(): IPollGetMachineListAsReportRequestAction {
    return {
      type: MachineListActions.POLL_GET_MACHINE_LIST_AS_REPORT_REQUEST,
    };
  }

  public static pollGetMachineListAsReportError({
    error,
  }: IActionCreatorErrorOptions): IPollGetMachineListAsReportErrorAction {
    return {
      type: MachineListActions.POLL_GET_MACHINE_LIST_AS_REPORT_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeMachineListTablePage {
    return {
      type: MachineListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeMachineListTablePageSize {
    return {
      type: MachineListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof MachineListActions.RESET_STATE> {
    return {
      type: MachineListActions.RESET_STATE,
    };
  }

  public static getMachineListFiltersRequest(): IGetMachineListFiltersRequestAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_FILTERS_REQUEST,
    };
  }

  public static getMachineListFiltersSuccess(
    availableFilters: IGetMachineListFiltersSuccessOptions
  ): IGetMachineListFiltersSuccessAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_FILTERS_SUCCESS,
      payload: availableFilters,
    };
  }

  public static getMachineListFiltersError({ error }: IActionCreatorErrorOptions): IGetMachineListFiltersErrorAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_FILTERS_ERROR,
      error,
    };
  }

  public static setActiveMaterialNumberFilter(materialNumbers: Optional<string[]>): ISetActiveMaterialNumberFilter {
    return {
      type: MachineListActions.SET_ACTIVE_MATERIAL_NUMBER_FILTER,
      payload: {
        materialNumbers,
      },
    };
  }

  public static setActiveSiteFilter(sites: string[]): ISetActiveSiteFilter {
    return {
      type: MachineListActions.SET_ACTIVE_SITE_FILTER,
      payload: {
        sites,
      },
    };
  }

  public static setActiveMachineTypeFilter({
    machineTypes,
  }: {
    machineTypes: Optional<string[]>;
  }): ISetActiveMachineTypeFilter {
    return {
      type: MachineListActions.SET_ACTIVE_MACHINE_TYPE_FILTER,
      payload: {
        machineTypes,
      },
    };
  }

  public static setActiveMachineStatusFilter(machineStatus: Optional<string>): ISetActiveMachineStatusFilter {
    return {
      type: MachineListActions.SET_ACTIVE_MACHINE_STATUS_FILTER,
      payload: {
        machineStatus,
      },
    };
  }

  public static setActiveMachinePeriodFilter(period: InputPeriod): ISetActiveMachinePeriodFilter {
    return {
      type: MachineListActions.SET_ACTIVE_MACHINE_PERIOD_FILTER,
      payload: {
        period,
      },
    };
  }

  public static setSearchText(searchText: Optional<string>): ISetSearchTextFilter {
    return {
      type: MachineListActions.SET_SEARCH_TEXT_FILTER,
      payload: {
        searchText,
      },
    };
  }

  public static setActiveSortField(field: Optional<string>): ISetActiveSortField {
    return {
      type: MachineListActions.SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setActiveSortOrder(order: Optional<SortOrders>): ISetActiveSortOrder {
    return {
      type: MachineListActions.SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getMachineListCoordinatesRequest({
    filter,
  }: IGetMachineListCoordinatesRequestOptions): IGetMachineListCoordinatesRequestAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_COORDINATES_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getMachineListCoordinatesSuccess(
    data: IGetMachineListCoordinatesSuccessOptions
  ): IGetMachineListCoordinatesSuccessAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_COORDINATES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListCoordinatesPicturesSuccess(
    data: IGetMachineListCoordinatesPicturesSuccessOptions
  ): IGetMachineListCoordinatesPicturesSuccessAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListCoordinatesError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineListCoordinatesErrorAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_COORDINATES_ERROR,
      error,
    };
  }

  public static requestExportMachinesRequest({
    filter,
    sortOptions,
    timezone,
    lang,
  }: IRequestExportMachinesRequestOptions): IRequestExportMachinesRequestAction {
    return {
      type: MachineListActions.REQUEST_EXPORT_MACHINES_REQUEST,
      payload: {
        filter,
        sortOptions,
        timezone,
        lang,
      },
    };
  }

  public static requestExportMachinesSuccess(
    data: IRequestExportMachinesSuccessOptions
  ): IRequestExportMachinesSuccessAction {
    return {
      type: MachineListActions.REQUEST_EXPORT_MACHINES_SUCCESS,
      payload: data,
    };
  }

  public static requestExportMachinesError({ error }: IActionCreatorErrorOptions): IRequestExportMachinesErrorAction {
    return {
      type: MachineListActions.REQUEST_EXPORT_MACHINES_ERROR,
      error,
    };
  }

  public static requestExportMachinesReportRequest({
    period,
    search,
    filter,
    sortOptions,
    timezone,
    lang,
    columns,
  }: IRequestExportMachinesReportRequestOptions): IRequestExportMachinesReportRequestAction {
    return {
      type: MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_REQUEST,
      payload: {
        period,
        search,
        filter,
        sortOptions,
        timezone,
        lang,
        columns,
      },
    };
  }

  public static requestExportMachinesReportSuccess(
    data: IRequestExportMachinesReportSuccessOptions
  ): IRequestExportMachinesReportSuccessAction {
    return {
      type: MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_SUCCESS,
      payload: data,
    };
  }

  public static requestExportMachinesReportError({
    error,
  }: IActionCreatorErrorOptions): IRequestExportMachinesReportErrorAction {
    return {
      type: MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_ERROR,
      error,
    };
  }

  public static pollGetExportMachinesFileRequest({
    requestId,
  }: IPollGetExportMachinesFileRequestOptions): IPollGetExportMachinesFileRequestAction {
    return {
      type: MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_REQUEST,
      payload: {
        requestId,
      },
    };
  }

  public static pollGetExportMachinesFileSuccess(
    data: IPollGetExportMachinesFileSuccessOptions
  ): IPollGetExportMachinesFileSuccessAction {
    return {
      type: MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_SUCCESS,
      payload: data,
    };
  }

  public static pollGetExportMachinesFileError({
    error,
  }: IActionCreatorErrorOptions): IPollGetExportMachinesFileErrorAction {
    return {
      type: MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_ERROR,
      error,
    };
  }

  public static exportResetState(): Action<typeof MachineListActions.EXPORT_RESET_STATE> {
    return {
      type: MachineListActions.EXPORT_RESET_STATE,
    };
  }

  public static showMachineReportSubscriptionsDrawer(): IShowMachineReportSubscriptionsDrawerAction {
    return {
      type: MachineListActions.SHOW_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER,
    };
  }

  public static hideMachineReportSubscriptionsDrawer(): IHideMachineReportSubscriptionsDrawerAction {
    return {
      type: MachineListActions.HIDE_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER,
    };
  }

  public static listMachineReportSubscriptionsRequest(): IListMachineReportSubscriptionsRequestAction {
    return {
      type: MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST,
    };
  }

  public static listMachineReportSubscriptionsSuccess(
    data: IListMachineReportSubscriptionsSuccessOptions
  ): IListMachineReportSubscriptionsSuccessAction {
    return {
      type: MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS,
      payload: data,
    };
  }

  public static listMachineReportSubscriptionsError({
    error,
  }: IActionCreatorErrorOptions): IListMachineReportSubscriptionsErrorAction {
    return {
      type: MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_ERROR,
      error,
    };
  }

  public static resetMachineReportSubscriptionsRequest({
    input,
  }: IResetMachineReportSubscriptionsRequestOptions): IResetMachineReportSubscriptionsRequestAction {
    return {
      type: MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST,
      payload: {
        input,
      },
    };
  }

  public static resetMachineReportSubscriptionsSuccess(
    data: IResetMachineReportSubscriptionsSuccessOptions
  ): IResetMachineReportSubscriptionsSuccessAction {
    return {
      type: MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS,
      payload: data,
    };
  }

  public static resetMachineReportSubscriptionsError({
    error,
  }: IActionCreatorErrorOptions): IResetMachineReportSubscriptionsErrorAction {
    return {
      type: MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_ERROR,
      error,
    };
  }

  public static getMachineFilterRequest({
    filter,
    timezone,
  }: IGetMachineFilterRequestOptions): IGetMachineFilterRequestAction {
    return {
      type: MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_REQUEST,
      payload: {
        filter,
        timezone,
      },
    };
  }

  public static getMachineFilterSuccess(data: IGetMachineFilterSuccessOptions): IGetMachineFilterSuccessAction {
    return {
      type: MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_SUCCESS,
      payload: data,
    };
  }

  public static getMachineFilterError({ error }: IActionCreatorErrorOptions): IGetMachineFilterErrorAction {
    return {
      type: MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_ERROR,
      error,
    };
  }

  public static getMachineFilterResetState(): IGetMachineFilterResetStateAction {
    return {
      type: MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_RESET_STATE,
    };
  }
}
