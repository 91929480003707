import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import {
  MachineDetailsRobotFilterActivePeriodAction,
  MachineDetailsRobotFilterActiveRoutesNameAction,
  MachineDetailsRobotListCleaningDataCleanedAreaErrorAction,
  MachineDetailsRobotListCleaningDataCleanedAreaRequestAction,
  MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction,
  MachineDetailsRobotListCleaningDataCleanedHrsErrorAction,
  MachineDetailsRobotListCleaningDataCleanedHrsRequestAction,
  MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction,
  MachineDetailsRobotListConsumablesErrorAction,
  MachineDetailsRobotListConsumablesRequestAction,
  MachineDetailsRobotListConsumablesSuccessAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenErrorAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction,
  MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction,
  MachineDetailsRobotListRoutesNameErrorAction,
  MachineDetailsRobotListRoutesNameRequestAction,
  MachineDetailsRobotListRoutesNameSuccessAction,
} from './machineDetailsRobotActions.types';
import {
  END_DATE,
  OperatingHoursChartUtils,
  START_DATE_LAST_MONTH,
} from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { Optional } from 'lib/types/Optional';
import { ALL_VALUE_SELECT_OPTION } from 'config/constants';
import {
  CleaningTaskCleanedAreaByDayList,
  CleaningTaskCleanedHrsByDayList,
  CleaningTaskConsumablesByDayList,
  CleaningTaskDistanceDrivenByDayList,
  ResponseRobotCleaningRoutesList,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

interface MachineDetailsRobotFilter {
  isLoading: boolean;
  availableFilter: {
    robotCleaningRoutesList: ResponseRobotCleaningRoutesList;
  };
  active: {
    routeName: Optional<string>;
    period: {
      startDate: string;
      endDate: string;
    };
  };
}

interface MachineDetailsCleaningDataCleanedArea {
  isLoading: boolean;
  data: Optional<CleaningTaskCleanedAreaByDayList>;
}

interface MachineDetailsCleaningDataCleanedHrs {
  isLoading: boolean;
  data: Optional<CleaningTaskCleanedHrsByDayList>;
}

interface MachineDetailsCleaningDataConsumables {
  isLoading: boolean;
  data: Optional<CleaningTaskConsumablesByDayList>;
}

interface MachineDetailsCleaningDataDistanceDriven {
  isLoading: boolean;
  data: Optional<CleaningTaskDistanceDrivenByDayList>;
}

export interface MachineDetailsRobotState {
  machineDetailsRobotFilter: MachineDetailsRobotFilter;
  machineDetailsCleaningDataCleanedArea: MachineDetailsCleaningDataCleanedArea;
  machineDetailsCleaningDataCleanedHrs: MachineDetailsCleaningDataCleanedHrs;
  machineDetailsCleaningDataConsumables: MachineDetailsCleaningDataConsumables;
  machineDetailsCleaningDataDistanceDriven: MachineDetailsCleaningDataDistanceDriven;
}

export const initialState: MachineDetailsRobotState = {
  machineDetailsRobotFilter: {
    isLoading: false,
    availableFilter: {
      robotCleaningRoutesList: {
        data: {
          validRoute: [],
          unavailableRoute: [],
        },
      },
    },
    active: {
      routeName: '',
      period: {
        startDate: OperatingHoursChartUtils.prepareStartDate(START_DATE_LAST_MONTH).toISOString(),
        endDate: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
      },
    },
  },
  machineDetailsCleaningDataCleanedArea: {
    isLoading: false,
    data: {
      cleanedAreaList: [
        {
          cleanedArea: 0,
          finishedAt: '',
        },
      ],
      cleanedAreaSummary: {
        periodCleanedArea: 0,
        totalCleanedArea: 0,
      },
    },
  },
  machineDetailsCleaningDataCleanedHrs: {
    isLoading: false,
    data: {
      cleanedHrsList: [
        {
          cleanedHrs: 0,
          date: '',
        },
      ],
      cleanedHrsSummary: {
        periodCleanedHrs: 0,
        totalCleanedHrs: 0,
      },
    },
  },
  machineDetailsCleaningDataConsumables: {
    isLoading: false,
    data: {
      consumablesList: [
        {
          consumptions: {
            battery: 0,
            detergents: 0,
            water: 0,
          },
          finishedAt: '',
        },
      ],
      consumablesSummary: {
        periodBattery: 0,
        periodDetergents: 0,
        periodWater: 0,
      },
    },
  },
  machineDetailsCleaningDataDistanceDriven: {
    isLoading: false,
    data: {
      distanceDrivenList: [
        {
          distanceDriven: 0,
          finishedAt: '',
        },
      ],
      distanceDrivenSummary: {
        periodDistanceDriven: 0,
        totalDistanceDriven: 0,
      },
    },
  },
};

const machineDetailsRobotSlice = createSlice({
  name: 'machine-details-robot',
  initialState,
  reducers: {
    // RoutesNameList
    machineDetailsRobotGetRoutesNameListRequest: (state, _action: MachineDetailsRobotListRoutesNameRequestAction) => {
      state.machineDetailsRobotFilter.isLoading = true;
      return state;
    },
    machineDetailsRobotGetRoutesNameListSuccess: (state, action: MachineDetailsRobotListRoutesNameSuccessAction) => {
      state.machineDetailsRobotFilter.isLoading = false;
      const { robotCleaningRoutesList } = action.payload || {};
      if (robotCleaningRoutesList) {
        state.machineDetailsRobotFilter.availableFilter.robotCleaningRoutesList = robotCleaningRoutesList;
      } else {
        state.machineDetailsRobotFilter.availableFilter.robotCleaningRoutesList =
          initialState.machineDetailsRobotFilter.availableFilter.robotCleaningRoutesList;
      }

      state.machineDetailsRobotFilter.active.routeName = ALL_VALUE_SELECT_OPTION;

      return state;
    },
    machineDetailsRobotGetRoutesNameListError: (state, _action: MachineDetailsRobotListRoutesNameErrorAction) => {
      state.machineDetailsRobotFilter.isLoading = false;
      return state;
    },

    // MachineDetailsRobotFilter
    machineDetailsRobotFilterActiveRoutesName: (state, action: MachineDetailsRobotFilterActiveRoutesNameAction) => {
      state.machineDetailsRobotFilter.active.routeName = action.payload;
      return state;
    },
    machineDetailsRobotFilterActivePeriod: (state, action: MachineDetailsRobotFilterActivePeriodAction) => {
      state.machineDetailsRobotFilter.active.period = action.payload;
      return state;
    },
    machineDetailsRobotFilterResetState: state => {
      state.machineDetailsRobotFilter = initialState.machineDetailsRobotFilter;
      return state;
    },

    // Cleaning data
    // Cleaned area
    machineDetailsRobotListCleaningDataCleanedAreaRequest: (
      state,
      _action: MachineDetailsRobotListCleaningDataCleanedAreaRequestAction
    ) => {
      state.machineDetailsCleaningDataCleanedArea.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedAreaSuccess: (
      state,
      action: MachineDetailsRobotListCleaningDataCleanedAreaSuccessAction
    ) => {
      state.machineDetailsCleaningDataCleanedArea.isLoading = false;

      state.machineDetailsCleaningDataCleanedArea.data = action.payload?.cleaningTaskCleanedAreaByDayList?.data;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedAreaError: (
      state,
      _action: MachineDetailsRobotListCleaningDataCleanedAreaErrorAction
    ) => {
      state.machineDetailsCleaningDataCleanedArea.isLoading = false;
      return state;
    },

    // Cleaned hours
    machineDetailsRobotListCleaningDataCleanedHrsRequest: (
      state,
      _action: MachineDetailsRobotListCleaningDataCleanedHrsRequestAction
    ) => {
      state.machineDetailsCleaningDataCleanedHrs.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedHrsSuccess: (
      state,
      action: MachineDetailsRobotListCleaningDataCleanedHrsSuccessAction
    ) => {
      state.machineDetailsCleaningDataCleanedHrs.isLoading = false;

      state.machineDetailsCleaningDataCleanedHrs.data = action.payload?.cleaningTaskCleanedHrsByDayList?.data;
      return state;
    },
    machineDetailsRobotListCleaningDataCleanedHrsError: (
      state,
      _action: MachineDetailsRobotListCleaningDataCleanedHrsErrorAction
    ) => {
      state.machineDetailsCleaningDataCleanedHrs.isLoading = false;
      return state;
    },

    // Consumables
    machineDetailsRobotListCleaningDataConsumablesRequest: (
      state,
      _action: MachineDetailsRobotListConsumablesRequestAction
    ) => {
      state.machineDetailsCleaningDataConsumables.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataConsumablesSuccess: (
      state,
      action: MachineDetailsRobotListConsumablesSuccessAction
    ) => {
      state.machineDetailsCleaningDataConsumables.isLoading = false;

      state.machineDetailsCleaningDataConsumables.data = action.payload?.cleaningTaskConsumablesByDayList?.data;
      return state;
    },
    machineDetailsRobotListCleaningDataConsumablesError: (
      state,
      _action: MachineDetailsRobotListConsumablesErrorAction
    ) => {
      state.machineDetailsCleaningDataConsumables.isLoading = false;
      return state;
    },

    // Distance driven
    machineDetailsRobotListCleaningDataDistanceDrivenRequest: (
      state,
      _action: MachineDetailsRobotListCleaningDataDistanceDrivenRequestAction
    ) => {
      state.machineDetailsCleaningDataDistanceDriven.isLoading = true;
      return state;
    },
    machineDetailsRobotListCleaningDataDistanceDrivenSuccess: (
      state,
      action: MachineDetailsRobotListCleaningDataDistanceDrivenSuccessAction
    ) => {
      state.machineDetailsCleaningDataDistanceDriven.isLoading = false;

      state.machineDetailsCleaningDataDistanceDriven.data = action.payload?.cleaningTaskDistanceDrivenByDayList.data;
      return state;
    },
    machineDetailsRobotListCleaningDataDistanceDrivenError: (
      state,
      _action: MachineDetailsRobotListCleaningDataDistanceDrivenErrorAction
    ) => {
      state.machineDetailsCleaningDataDistanceDriven.isLoading = false;
      return state;
    },
  },
});

// ACTIONS WITHOUT REDUCER CODE // START

export interface SubscribeToMachineUpdateActionPayload {
  customerId: string;
  machineId: string;
}
export type SubscribeToMachineUpdateAction = PayloadAction<SubscribeToMachineUpdateActionPayload>;
const subscribeToMachineUpdate = createAction<SubscribeToMachineUpdateActionPayload>(
  'machine-details-robot/subscribeToMachineUpdate'
);
export interface UnsubscribeFromMachineUpdateActionPayload {
  customerId: string;
  machineId: string;
}
export type UnsubscribeFromMachineUpdateAction = PayloadAction<UnsubscribeFromMachineUpdateActionPayload>;
const unsubscribeFromMachineUpdate = createAction<UnsubscribeFromMachineUpdateActionPayload>(
  'machine-details-robot/unsubscribeFromMachineUpdate'
);

// ACTIONS WITHOUT REDUCER CODE // END
export const MachineDetailsRobotActions = {
  ...machineDetailsRobotSlice.actions,
  subscribeToMachineUpdate,
  unsubscribeFromMachineUpdate,
};
export const machineDetailsRobotReducer = machineDetailsRobotSlice.reducer;
