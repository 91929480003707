import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as machineListSelectors from '../../state/machineListSelectors';
import { MachineListActions } from '../../state/machineListActions';
import { StyledMachineListTabMap } from './MachineListTabMap.styles';
import { MachineMap } from 'app/modules/machine-inventory/components/MachineMap/MachineMap';

export const MachineListTabMap = (): JSX.Element => {
  const dispatch = useDispatch();
  const isLoading = !!useSelector(machineListSelectors.selectIsLoading);
  const isMapLoading = !!useSelector(machineListSelectors.selectIsMapLoading);
  const mapMachines = useSelector(machineListSelectors.selectMapMachines) || [];
  const machines = useSelector(machineListSelectors.selectData);

  useEffect(() => {
    if (isLoading) return;
    dispatch(
      MachineListActions.getMachineListCoordinatesRequest({
        filter: {
          ids: (machines || []).map(machine => machine.machineId),
        },
      })
    );
  }, [dispatch, isLoading, machines]);

  return (
    <StyledMachineListTabMap className="machine-list__map-container">
      <MachineMap className="machine-list__machine-map" machines={mapMachines} isLoading={isLoading || isMapLoading} />
    </StyledMachineListTabMap>
  );
};
