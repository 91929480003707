import { useTranslation } from 'react-i18next';
import { Empty, TablePaginationConfig } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { MachineListActions } from '../../state/machineListActions';
import * as machineListSelectors from '../../state/machineListSelectors';
import { ColumnsTypeForCustomizableTable, Table } from 'lib/components/Table/Table';
import { MachineAsReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import {
  MachinesReport,
  Maybe,
  SortOrders,
  Column as MachineListColumn,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RoutePaths } from 'config/route-paths';

export const MachineListTabTable = ({
  customizedTableColumns,
}: {
  customizedTableColumns: ColumnsTypeForCustomizableTable<MachineListColumn, MachinesReport>;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const machines = useSelector(machineListSelectors.selectData) || [];
  const isLoading = !!useSelector(machineListSelectors.selectIsLoading);
  const [selectedRowKey, setSelectedRowKey] = useState<string>('');

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MachineAsReport> | SorterResult<MachineAsReport>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(MachineListActions.setActiveSortField(sorter.columnKey as Maybe<string>));
    dispatch(MachineListActions.setActiveSortOrder(sorterOrder as Maybe<SortOrders>));
  };

  const rowClassName = (record: MachineAsReport): string =>
    record.machineId === selectedRowKey ? 'table__selected-row' : '';

  return (
    <Table
      dataSource={machines}
      loading={isLoading}
      className="machine-list__table"
      columns={customizedTableColumns}
      rowKey="machineId"
      onRow={(machineRowData: MachineAsReport): { onClick(): void } => ({
        onClick: (): void => {
          analyticsLinkActivated({
            linkName: AnalyticsLink.MACHINE_LIST_OPEN_DETAILS,
          });
          setSelectedRowKey(machineRowData.machineId);
          navigate(RoutePaths.MACHINE_DETAILS.replace(':id', machineRowData.machineId));
        },
      })}
      rowClassName={rowClassName}
      onChange={onChange}
      sortDirections={['ascend', 'descend', 'ascend']}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('machineList.table.noMachinesFound')} />,
      }}
      // y value needs to match value set in CSS rule for
      // .ant-table-container, .ant-table-body
      scroll={{ y: 600 }}
      showScrollButtons={true}
    />
  );
};
