import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyPrimaryButton } from '../../../../../../lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from '../../../../../../lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import {
  selectDeleteMachineAttachmentMachine,
  selectDeleteMachineAttachment,
  selectIsDeleteMachineAttachmentModalLoading,
  selectIsDeleteMachineAttachmentModalVisible,
} from '../../state/machineModalsSelectors';
import { MachineModalsActions } from '../../state/machineModalsSlice';
import { StyledDeleteMachineAttachmentModal } from './DeleteMachineAttachmentModal.styles';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { MachineDetailsActions } from 'app/modules/machine-inventory/machine-details/state/machineDetailsActions';

export interface DeleteMachineAttachmentModalProps {
  pageSize: number;
}

export const DeleteMachineAttachmentModal = ({ pageSize }: DeleteMachineAttachmentModalProps): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  const machine = useSelector(selectDeleteMachineAttachmentMachine);
  const attachment = useSelector(selectDeleteMachineAttachment);
  const isVisible = useSelector(selectIsDeleteMachineAttachmentModalVisible);
  const isLoading = useSelector(selectIsDeleteMachineAttachmentModalLoading);

  const handleCancel = useCallback(() => {
    dispatch(MachineModalsActions.hideDeleteMachineAttachmentModal());
  }, [dispatch]);

  const handleOk = useCallback(() => {
    if (!attachment || !machine) return;

    analyticsLinkActivated({
      linkName: AnalyticsLink.DELETE_MACHINE_ATTACHMENTS,
    });

    dispatch(
      MachineDetailsActions.deleteMachineAttachmentRequest({
        machineId: machine.id,
        attachmentId: attachment.id,
        pageSize,
      })
    );
  }, [analyticsLinkActivated, attachment, dispatch, machine, pageSize]);

  useAnalyticsSetForm({
    name: AnalyticsForm.DELETE_MACHINE_ATTACHMENTS,
    fields: {
      attachment,
    },
    isVisible,
  });

  if (!attachment) {
    return null;
  }

  return (
    <StyledDeleteMachineAttachmentModal
      className="delete-machine-attachment-modal"
      title={t('machineModals.deleteMachineAttachment.title')}
      open={isVisible}
      footer={[
        <LegacySecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('machineModals.deleteMachineAttachment.deleteAttachment')}
        </LegacyPrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('machineModals.deleteMachineAttachment.modalBody')}</p>
      <h3>{attachment.fileName}</h3>
    </StyledDeleteMachineAttachmentModal>
  );
};
