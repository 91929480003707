import { createSlice } from '@reduxjs/toolkit';
import { Machine } from '../../interfaces/Machine.types';
import { IMachineAttachment } from '../../interfaces/MachineAttachment.types';
import {
  ICheckNameAddMachineAttachmentModalRequestAction,
  ICheckNameAddMachineAttachmentModalErrorAction,
  ICheckNameAddMachineAttachmentModalSuccessAction,
  IShowDeleteMachineAttachmentModalAction,
  IGetSitesListForSelectSuccessAction,
  IGetSitesListForSelectErrorAction,
  IGetSitesListForSelectRequestAction,
} from './machineModalsActions.types';
import { Optional } from 'lib/types/Optional';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineModalsState {
  editMachineName: {
    visible: boolean;
    isLoading: boolean;
  };
  addMachineAttachment: {
    isFileNameExisting: boolean;
    visible: boolean;
    isLoading: boolean;
  };
  deleteMachineAttachment: {
    machine: Optional<Machine>;
    attachment: Optional<IMachineAttachment>;
    visible: boolean;
    isLoading: boolean;
  };
  editMachineInfo: {
    visible: boolean;
    isLoading: boolean;
    selects: {
      sites: Site[];
      isLoading: boolean;
    };
  };
}

export const initialState: MachineModalsState = {
  editMachineName: {
    visible: false,
    isLoading: false,
  },
  addMachineAttachment: {
    isFileNameExisting: false,
    visible: false,
    isLoading: false,
  },
  deleteMachineAttachment: {
    machine: null,
    attachment: null,
    visible: false,
    isLoading: false,
  },
  editMachineInfo: {
    visible: false,
    isLoading: false,
    selects: {
      sites: [],
      isLoading: false,
    },
  },
};

const machineModalsSlice = createSlice({
  name: 'machineModals',
  initialState,
  reducers: {
    /**
     * @deprecated use showEditMachineInfoModal instead, TODO: remove it with all relevant things
     */
    showEditMachineNameModal: state => {
      state.editMachineName.visible = true;
      return state;
    },
    /**
     * @deprecated use hideEditMachineInfoModal instead, TODO: remove it with all relevant things
     */
    hideEditMachineNameModal: state => {
      state.editMachineName.visible = false;
      return state;
    },
    editMachineNameModalIsLoading: state => {
      state.editMachineName.isLoading = true;
      return state;
    },
    editMachineNameModalIsNotLoading: state => {
      state.editMachineName.isLoading = false;
      return state;
    },
    showAddMachineAttachmentModal: state => {
      state.addMachineAttachment.visible = true;
      return state;
    },
    hideAddMachineAttachmentModal: state => {
      state.addMachineAttachment.visible = false;
      state.addMachineAttachment.isFileNameExisting = false;
      return state;
    },
    addMachineAttachmentModalIsLoading: state => {
      state.addMachineAttachment.isLoading = true;
      return state;
    },
    addMachineAttachmentModalIsNotLoading: state => {
      state.addMachineAttachment.isLoading = false;
      return state;
    },
    addMachineAttachmentModalIsNotFileNameExisting: state => {
      state.addMachineAttachment.isFileNameExisting = false;
      return state;
    },
    checkNameAddMachineAttachmentModalRequest: (state, _action: ICheckNameAddMachineAttachmentModalRequestAction) => {
      state.addMachineAttachment.isLoading = true;
      return state;
    },
    checkNameAddMachineAttachmentModalSuccess: (state, action: ICheckNameAddMachineAttachmentModalSuccessAction) => {
      state.addMachineAttachment.isLoading = false;
      state.addMachineAttachment.isFileNameExisting = action.payload?.attachmentNameCheck.data?.exists || false;
      return state;
    },
    checkNameAddMachineAttachmentModalError: (state, _action: ICheckNameAddMachineAttachmentModalErrorAction) => {
      state.addMachineAttachment.isLoading = false;
      return state;
    },
    showDeleteMachineAttachmentModal: (state, action: IShowDeleteMachineAttachmentModalAction) => {
      state.deleteMachineAttachment.machine = action.payload.machine;
      state.deleteMachineAttachment.attachment = action.payload.attachment;
      state.deleteMachineAttachment.visible = true;
      return state;
    },
    hideDeleteMachineAttachmentModal: state => {
      state.deleteMachineAttachment.visible = false;
      state.deleteMachineAttachment.machine = initialState.deleteMachineAttachment.machine;
      state.deleteMachineAttachment.attachment = initialState.deleteMachineAttachment.attachment;
      return state;
    },
    deleteMachineAttachmentModalIsLoading: state => {
      state.deleteMachineAttachment.isLoading = true;
      return state;
    },
    deleteMachineAttachmentModalIsNotLoading: state => {
      state.deleteMachineAttachment.isLoading = false;
      return state;
    },
    editMachineInfoModalIsLoading: state => {
      state.editMachineInfo.isLoading = true;
      return state;
    },
    editMachineInfoModalIsNotLoading: state => {
      state.editMachineInfo.isLoading = false;
      return state;
    },
    showEditMachineInfoModal: state => {
      state.editMachineInfo.visible = true;
      return state;
    },
    hideEditMachineInfoModal: state => {
      state.editMachineInfo.visible = false;
      return state;
    },
    getSitesListForSelectRequest: (state, _action: IGetSitesListForSelectRequestAction) => {
      state.editMachineInfo.selects.isLoading = true;
      return state;
    },
    getSitesListForSelectSuccess: (state, action: IGetSitesListForSelectSuccessAction) => {
      state.editMachineInfo.selects.isLoading = false;
      state.editMachineInfo.selects.sites = action.payload?.sites.data || [];
      return state;
    },
    getSitesListForSelectError: (state, _action: IGetSitesListForSelectErrorAction) => {
      state.editMachineInfo.selects.isLoading = false;
      return state;
    },
  },
});

export const MachineModalsActions = machineModalsSlice.actions;
export const machineModalsReducer = machineModalsSlice.reducer;
