import { produce } from 'immer';
import { AnyAction } from 'redux';
import { isNil } from 'lodash-es';
import { Optional } from '../../../../../lib/types/Optional';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  Machine,
  MachinesExportRequest,
  MachineList,
  MachineListAvailableFilters,
  MachinesExportGetFile,
  MachineAsReport,
  MachinesReportExportRequest,
  MachineReportSubscriptionsListResponse,
  MachineReportSubscriptionsResetResponse,
} from '../../interfaces/Machine.types';
import { MachineUtils } from '../../utils/MachineUtils';
import { MachineClientListAsReportOptions } from '../../MachineClient';
import { MachineListActions } from './machineListActions';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import {
  InputPeriod,
  MachineConnectionStatus,
  MachineFilter,
  MachinesReportSubscription,
  Maybe,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';

export interface MachineListState {
  data: Optional<MachineAsReport[]>;
  requestParams: Optional<MachineClientListAsReportOptions>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  paginationTokens: PaginationTokens;
  page: number;
  pageSize: number;
  filters: {
    available: MachineListAvailableFilters;
    active: {
      machineTypes: Optional<string[]>;
      materialNumbers: Optional<string[]>;
      sites: Optional<string[]>;
      machineStatus: Optional<string>;
      connectionStatuses: Optional<MachineConnectionStatus[]>;
      searchText: Optional<string>;
      period: InputPeriod;
    };
    isLoading: boolean;
  };
  map: {
    data: Optional<Machine[]>;
    isLoading: Optional<boolean>;
    arePicturesLoading: boolean;
  };
  sortOptions: {
    field: Maybe<string>;
    order: Maybe<SortOrders>;
  };
  export: {
    isLoading: Optional<boolean>;
    requestId: Optional<string>;
    downloadUrl: Optional<string>;
  };
  subscription: {
    visible: boolean;
    data: MachinesReportSubscription[];
    isLoadingSubscriptions: boolean;
    isSavingSubscription: boolean;
  };
  newFilters: {
    data: Optional<MachineFilter>;
    isLoading: Optional<boolean>;
  };
}

export const initialState: MachineListState = {
  data: null,
  requestParams: null,
  totalCount: null,
  isLoading: null,
  paginationTokens: {},
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  filters: {
    available: {
      machineTypes: [],
      sites: [],
      machineStatus: [],
    },
    active: {
      machineTypes: [],
      materialNumbers: [],
      sites: [],
      machineStatus: '',
      connectionStatuses: undefined,
      searchText: undefined,
      period: {
        startAt: OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
        endAt: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
      },
    },
    isLoading: false,
  },
  map: {
    data: null,
    isLoading: null,
    arePicturesLoading: true,
  },
  sortOptions: {
    field: null,
    order: null,
  },
  export: {
    isLoading: null,
    requestId: null,
    downloadUrl: null,
  },
  subscription: {
    visible: false,
    data: [],
    isLoadingSubscriptions: false,
    isSavingSubscription: false,
  },
  newFilters: {
    data: {
      classifications: [],
      dataStatuses: [],
      financeTypes: [],
      machineTypes: [],
      servicePackages: [],
      sites: [],
      subclassifications: [],
    },
    isLoading: null,
  },
};

export const machineListReducer = (state = initialState, action: AnyAction): MachineListState =>
  produce(state, draft => {
    switch (action.type) {
      case MachineListActions.GET_MACHINE_LIST_AS_REPORT_REQUEST: {
        draft.isLoading = true;
        draft.requestParams = action.payload;
        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_AS_REPORT_SUCCESS: {
        const {
          machinesReport: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.isLoading = false;
        draft.data = data;
        draft.totalCount = totalCount;

        if (paginationToken) {
          draft.paginationTokens[draft.page + 1] = paginationToken;
        }

        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_AS_REPORT_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case MachineListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.page = page;
        return draft;
      }

      case MachineListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = pageSize;
        return draft;
      }

      case MachineListActions.RESET_STATE: {
        return initialState;
      }

      case MachineListActions.GET_MACHINE_LIST_FILTERS_REQUEST: {
        draft.filters.isLoading = true;
        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_FILTERS_SUCCESS: {
        draft.filters.isLoading = false;
        draft.filters.available = action.payload;

        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_FILTERS_ERROR: {
        draft.filters.isLoading = false;
        return draft;
      }

      case MachineListActions.SET_ACTIVE_MATERIAL_NUMBER_FILTER: {
        draft.filters.active.materialNumbers = action.payload.materialNumbers;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case MachineListActions.SET_ACTIVE_SITE_FILTER: {
        draft.filters.active.sites = action.payload.sites;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case MachineListActions.SET_ACTIVE_MACHINE_TYPE_FILTER: {
        draft.filters.active.machineTypes = action.payload.machineTypes;

        // Reset material number when changing machine type
        draft.filters.active.materialNumbers = [];
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case MachineListActions.SET_ACTIVE_MACHINE_STATUS_FILTER: {
        const { machineStatus, connectionStatuses } = action.payload;

        draft.filters.active.machineStatus = machineStatus;
        draft.filters.active.connectionStatuses = connectionStatuses;

        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case MachineListActions.SET_ACTIVE_MACHINE_PERIOD_FILTER: {
        const { period } = action.payload;

        draft.filters.active.period = period;
        return draft;
      }

      case MachineListActions.SET_SEARCH_TEXT_FILTER: {
        const { searchText } = action.payload;

        draft.filters.active.searchText = searchText;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case MachineListActions.SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.sortOptions.field = field;
        return draft;
      }

      case MachineListActions.SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.sortOptions.order = order;
        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_COORDINATES_REQUEST: {
        draft.map.isLoading = true;
        draft.map.arePicturesLoading = true;
        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_COORDINATES_SUCCESS: {
        const {
          machines: { data },
        } = action.payload as MachineList;

        draft.map.data = data.filter(machine => !isNil(machine.location));
        draft.map.isLoading = false;
        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_COORDINATES_PICTURES_SUCCESS: {
        const {
          machines: { data: machineVariantData },
        } = action.payload;

        draft.map.arePicturesLoading = false;
        draft.map.data = draft.map.data?.map(machineWithoutVariantData =>
          MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
        );

        return draft;
      }

      case MachineListActions.GET_MACHINE_LIST_COORDINATES_ERROR: {
        draft.map.isLoading = false;
        return draft;
      }

      case MachineListActions.REQUEST_EXPORT_MACHINES_REQUEST: {
        draft.export.isLoading = true;
        return draft;
      }

      case MachineListActions.REQUEST_EXPORT_MACHINES_SUCCESS: {
        const {
          machinesListExportRequest: { data },
        } = action.payload as MachinesExportRequest;

        draft.export.requestId = data.requestId;

        return draft;
      }

      case MachineListActions.REQUEST_EXPORT_MACHINES_ERROR: {
        draft.export.isLoading = false;
        return draft;
      }

      case MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_REQUEST: {
        draft.export.isLoading = true;
        return draft;
      }

      case MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_SUCCESS: {
        const {
          machinesReportExportRequest: { data },
        } = action.payload as MachinesReportExportRequest;

        draft.export.requestId = data.requestId;
        draft.export.isLoading = false;

        return draft;
      }

      case MachineListActions.REQUEST_EXPORT_MACHINES_REPORT_ERROR: {
        draft.export.isLoading = false;
        return draft;
      }

      case MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_SUCCESS: {
        const {
          machinesExportFile: { data },
        } = action.payload as MachinesExportGetFile;

        draft.export.downloadUrl = data.downloadUrl;
        draft.export.isLoading = false;

        return draft;
      }

      case MachineListActions.POLL_GET_EXPORT_MACHINES_FILE_ERROR: {
        draft.export.isLoading = false;
        return draft;
      }

      case MachineListActions.EXPORT_RESET_STATE: {
        draft.export = initialState.export;
        return draft;
      }

      case MachineListActions.SHOW_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER: {
        draft.subscription.visible = true;
        return draft;
      }
      case MachineListActions.HIDE_MACHINE_REPORT_SUBSCRIPTIONS_DRAWER: {
        draft.subscription.visible = false;
        return draft;
      }

      case MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST: {
        draft.subscription.isLoadingSubscriptions = true;
        return draft;
      }

      case MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS: {
        const {
          machinesReportSubscriptionsList: { data },
        } = action.payload as MachineReportSubscriptionsListResponse;

        draft.subscription.data = data;
        draft.subscription.isLoadingSubscriptions = false;

        return draft;
      }

      case MachineListActions.LIST_MACHINE_REPORT_SUBSCRIPTIONS_ERROR: {
        draft.subscription.isLoadingSubscriptions = false;
        return draft;
      }

      case MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_REQUEST: {
        draft.subscription.isSavingSubscription = true;
        return draft;
      }

      case MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_SUCCESS: {
        const {
          machinesReportSubscriptionsReset: { data },
        } = action.payload as MachineReportSubscriptionsResetResponse;

        draft.subscription.data = data;
        draft.subscription.isSavingSubscription = false;
        draft.subscription.visible = false;
        return draft;
      }

      case MachineListActions.RESET_MACHINE_REPORT_SUBSCRIPTIONS_ERROR: {
        draft.subscription.isSavingSubscription = false;
        return draft;
      }

      case MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_REQUEST: {
        draft.newFilters.isLoading = true;
        return draft;
      }

      case MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_SUCCESS: {
        const {
          machineFilter: { data },
        } = action.payload;
        draft.newFilters.data = data || initialState.newFilters.data;
        draft.newFilters.isLoading = false;
        return draft;
      }

      case MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_ERROR: {
        draft.newFilters.isLoading = false;
        return draft;
      }

      case MachineListActions.GET_MACHINE_AVAILABLE_FILTERS_RESET_STATE: {
        draft.newFilters = initialState.newFilters;
        return draft;
      }

      default:
        return draft;
    }
  });
