export class FormDataBuilder {
  public static buildUploadFormFromPresignedUrl({
    file,
    fields,
  }: {
    file: File;
    fields: { name: string; value: string }[];
  }): FormData {
    const formData = new FormData();

    fields.forEach(field => {
      formData.append(field.name, field.value);
    });
    formData.append('file', file);

    return formData;
  }
}
